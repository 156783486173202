<template>
  <div>
    <el-table
      :data="(agents || []).slice((pageNumber - 1) * PAGE_SIZE, PAGE_SIZE * pageNumber)"
      size="small"
      style="width: 100%"
      empty-text="No agent found"
      @row-click="agent => $emit('view-agent', agent)">
      <template slot="empty">
        <div class="text-center p-10 text-xs text-black leading-loose">
          <h1 class="text-black text-base mb-5 font-semibold">
            {{ $t('video.agents.table[0]') }}
          </h1>
          <span class="text-sm text-grey block mb-5">
            {{ $t('video.agents.table[1]') }}
          </span>
          <el-button
            v-if="canAddAgent"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="$emit('add-agent')">
            {{ $t('video.agents.index[2]') }}
          </el-button>
        </div>
      </template>

      <el-table-column
        prop="name"
        show-overflow-tooltip
        :label="$tc('column_labels.agent', 1)">
        <template slot-scope="nameScope">
          <div class="flex items-center">
            <div class="mr-3">
              <AgentAvatar
                :url="nameScope.row.ProfileUrl"
                :status="nameScope.row.IsLoggedIn"
                :disable-status="nameScope.row.LastLoginAt ? false : true"
                size="sm" />
            </div>
            <span
              v-if="nameScope.row.Name"
              v-html="$sanitize(highlight(nameScope.row.Name, search.trim()))" />
            <span
              v-else
              class="text-black"
              v-html="$sanitize(highlight(nameScope.row.Login, search.trim()))" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="email"
        show-overflow-tooltip
        :label="$t('fields.email')">
        <template slot-scope="emailScope">
          <span
            v-if="emailScope.row.Login"
            class="truncate"
            v-html="$sanitize(highlight(emailScope.row.Login, search.trim()))" />
        </template>
      </el-table-column>
      <el-table-column
        prop="onlineSince"
        show-overflow-tooltip
        align="right"
        :label="$t('video.agents.table[2]')">
        <template slot-scope="onlineSinceScope">
          <span
            v-if="onlineSinceScope.row.IsLoggedIn"
            class="truncate text-green">
            {{ $t('video.agents.table[3]') }}
          </span>
          <span
            v-else-if="onlineSinceScope.row.LastLoginAt"
            class="truncate">
            {{ timeFromNow(onlineSinceScope.row.LastLoginAt) }}
          </span>
          <span
            v-else
            class="text-grey">—</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        show-overflow-tooltip
        align="right"
        :label="$t('column_labels.status')">
        <template slot-scope="statusScope">
          <span class="truncate font-semi text-black">
            {{ statusScope.row.AgentStatus !== 'Unassigned' ? statusScope.row.AgentStatus : $t('video.agents.table[10]') }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        show-overflow-tooltip>
        <template slot="header">
          <el-tooltip
            :enterable="false"
            effect="dark"
            content="Agent needs to be Offline or Unavailable before you can re-assign to another queue"
            placement="bottom">
            <span class="text-xs flex items-center help-cursor inline-flex">
              {{ $t('column_labels.queue') }}
              <i class="el-icon-info ml-1" />
            </span>
          </el-tooltip>
        </template>
        <template slot-scope="statusScope">
          <span
            v-if="statusScope.row.AppId_Default !== null"
            class="truncate font-semi text-black">
            {{ `app-${ statusScope.row.AppId_Default || 0 }` }}
          </span>
          <span
            v-else
            class="truncate font-semi text-black">—</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="dateJoined"
        show-overflow-tooltip
        align="right"
        :label="getDateHeader(tz.timeZone, 'Date joined')">
        <template slot-scope="dateJoinedScope">
          <span
            v-if="dateJoinedScope.row.LastLoginAt"
            class="truncate">
            {{
              formatDate(dateJoinedScope.row.CreatedAt, {
                showCurrYear: false,
                utcOffset: tz.timeZoneOffset,
              })
            }}
          </span>
          <div
            v-else
            class="text-xs inline-block font-bold font-bold text-black rounded-sm vi-tag">
            {{ $t('video.agents.table[4]') }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="50">
        <template slot-scope="dropdownScope">
          <el-dropdown class="flex justify-end">
            <span class="el-dropdown-link flex items-center">
              <i class="material-icons text-base cursor-pointer hover:text-blue">
                more_vert
              </i>
            </span>
            <!-- If agent has already joined -->
            <el-dropdown-menu
              v-if="dropdownScope.row.UserStatus === 'A'"
              slot="dropdown">
              <el-dropdown-item @click.native="$emit('reset-password', dropdownScope.row.UserId)">
                {{ $t('video.agents.table[5]') }}
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!dropdownScope.row.IsLoggedIn"
                @click.native="openUpdateUserAppModal(dropdownScope.row)">
                {{ $t('video.agents.table[6]') }}
              </el-dropdown-item>
              <el-dropdown-item
                class="text-red"
                @click.native="$emit('remove-agent', dropdownScope.row.UserId)">
                {{ $t('video.agents.table[7]') }}
              </el-dropdown-item>
            </el-dropdown-menu>
            <!-- If agent have NOT joined -->
            <el-dropdown-menu
              v-else
              slot="dropdown">
              <el-dropdown-item @click.native="$emit('invite-agent', dropdownScope.row.UserId)">
                {{ $t('video.agents.table[8]') }}
              </el-dropdown-item>
              <el-dropdown-item
                class="text-red"
                @click.native="$emit('remove-agent', dropdownScope.row.UserId)">
                {{ $t('video.agents.table[9]') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-3 ml-auto flex justify-end">
      <el-pagination
        background
        :current-page="pageNumber"
        :total="(agents || []).length"
        :page-size="PAGE_SIZE"
        layout="prev, pager, next"
        @current-change="page => $emit('update-page-number', page)" />
    </div>
    <AppModal
      :is-visible="Object.keys(selectedAgentAppChange).length"
      :agent="selectedAgentAppChange"
      :apps="appList"
      @close-modal="selectedAgentAppChange = {}"
      @update-app="appId => updateApp(appId)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AgentAvatar from '@/components/AgentAvatar';
import AppModal from '@/components/Agents/UpdateAppModal';

import timeFormatter from '@/mixins/timeFormatter';
import agentMixin from '@/mixins/agent';
import highlight from '@/mixins/highlight';

export default {
  name: 'Table',

  components: {
    AgentAvatar,
    AppModal,
  },

  mixins: [timeFormatter, agentMixin, highlight],

  props: {
    agents: {
      default: () => [],
      type: Array,
    },
    search: {
      default: '',
      type: String,
    },
    canAddAgent: {
      default: false,
      type: Boolean,
    },
    appList: {
      default: () => [],
      type: Array,
    },
    pageNumber: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      selectedAgentAppChange: {},
      PAGE_SIZE: 10,
    };
  },

  computed: {
    ...mapGetters({
      tz: 'getTz',
    }),
  },

  methods: {
    openUpdateUserAppModal(agent) {
      this.selectedAgentAppChange = { ...agent };
    },

    updateApp(appId) {
      // set selected agent to empty object
      const { UserId } = this.selectedAgentAppChange;
      this.selectedAgentAppChange = {};

      this.$emit('update-agent-app', {
        userId: UserId,
        appId,
      });
    },
  },
};
</script>

<style lang="scss">
.vi-tag {
  line-height: 1;
  padding: 5px 7px 3px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
}
</style>

import Moment from 'moment';

export default {
  methods: {
    // Populate date filter option
    defaultDate(range = '') {
      let end = Moment().endOf('day').format('YYYY-MM-DD');
      let start = Moment().startOf('day').subtract(7, 'days').format('YYYY-MM-DD');

      if (range === 'last_month') {
        end = Moment().endOf('day').format('YYYY-MM-DD');
        start = Moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD');
      }

      if (range === 'last_3months') {
        end = Moment().endOf('day').format('YYYY-MM-DD');
        start = Moment().startOf('day').subtract(3, 'months').format('YYYY-MM-DD');
      }

      // default last week
      return {
        from: start,
        to: end,
      };
    },

    // Datepicker shortcut
    shortcutDateFilter() {
      const self = this;

      return {
        disabledDate: date => date > new Date(),
        shortcuts: [
          {
            text: self.$t('date_labels.last_week'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_month'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          }, {
            text: self.$t('date_labels.last_3_months'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      };
    },
  },
};

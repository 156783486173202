<template>
  <div>
    <div class="relative pb-20">
      <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
        {{ $t('sidebar_menu_parent.Video interaction') }}
      </h1>
      <div class="px-3 py-4 rounded bg-grey-darkest shadow flex justify-between items-center mb-10">
        <el-row>
          <el-col :span="12">
            <h1 class="flex text-base font-normal items-center text-white">
              {{ $t('video.dashboard.index[0]') }}
            </h1>
          </el-col>
          <el-col :span="12">
            <h1 class="flex text-base right font-normal text-white pt-2">
              {{ tz.timeZone }}
            </h1>
            <el-select
              v-model="weeksValue"
              :placeholder="$t('video.dashboard.index[1]')"
              size="small"
              class="mx-3 right"
              @change="val => updateFilterQuery({ weeks:val })">
              <el-option
                v-for="item in weekFilter"
                :key="item.value"
                :label="item.label"
                :value="item.value" />
            </el-select>
          </el-col>
        </el-row>
      </div>

      <div
        v-if="agents.length"
        class="mb-10">
        <h2 class="text-base font-semibold mb-3">
          {{ $t('video.dashboard.index[2]') }}
        </h2>
        <Agents :agents="agents" />
      </div>

      <div class="w-full">
        <h2 class="text-base font-semibold mt-10 mb-3">
          {{ $t('video.dashboard.index[3]') }}
        </h2>
        <el-row
          :gutter="10"
          type="flex"
          class="mb-4 flex-wrap">
          <!-- Total Calls -->
          <el-col
            :xs="12"
            :sm="12"
            :lg="6"
            class="sm:mb-3 xl:mb-0">
            <div
              v-loading="getStatsLoading"
              class="h-full shadow flex justify-center items-center rounded text-center p-5">
              <div class="flex flex-col">
                <div class="mb-2">
                  <span class="w-6 h-6 flex justify-center items-center bg-blue-lightest rounded-full mx-auto">
                    <i class="icon-v-videocam text-base text-blue" />
                  </span>
                </div>
                <span class="text-2xl text-black">
                  {{ getCallStats.Call || 0 }}
                  <div class="text-grey text-sm font-normal">
                    {{ $t('video.dashboard.index[4]') }}
                  </div>
                </span>
                <el-row
                  class="mt-4"
                  type="flex"
                  align="middle"
                  justify="center">
                  <i
                    v-if="getCallStats.CallTrend >= 0"
                    class="icon-v-trend-up text-green text-xs mr-2" />
                  <i
                    v-else
                    class="icon-v-trend-down text-red text-xs mr-2" />
                  <span class="text-sm text-black">
                    {{ `${getCallStats.CallTrend || 0}%` }}
                  </span>
                </el-row>
              </div>
            </div>
          </el-col>

          <!-- Total Call Duration -->
          <el-col
            :xs="12"
            :sm="12"
            :lg="6"
            class="sm:mb-3 xl:mb-0">
            <div
              v-loading="getStatsLoading"
              class="h-full shadow flex justify-center items-center rounded text-center p-5">
              <div class="flex flex-col">
                <div class="mb-2">
                  <span class="w-6 h-6 flex justify-center items-center bg-yellow-lightest rounded-full mx-auto">
                    <i class="icon-v-timer text-base text-yellow-dark" />
                  </span>
                </div>
                <span class="text-2xl text-black">
                  {{ decimalToDuration(getCallStats.DurationInMin) }}
                  <div class="text-grey text-sm font-normal">
                    {{ $t('video.dashboard.index[5]') }}
                  </div>
                </span>
                <el-row
                  class="mt-4"
                  type="flex"
                  align="middle"
                  justify="center">
                  <i
                    v-if="getCallStats.DurationTrend >= 0"
                    class="icon-v-trend-up text-green text-xs mr-2" />
                  <i
                    v-else
                    class="icon-v-trend-down text-red text-xs mr-2" />
                  <span class="text-sm text-black">
                    {{ `${getCallStats.DurationTrend || 0}%` }}
                  </span>
                </el-row>
              </div>
            </div>
          </el-col>

          <!-- Avg Call Duration -->
          <el-col
            :xs="12"
            :sm="12"
            :lg="6">
            <div
              v-loading="getStatsLoading"
              class="h-full shadow flex justify-center items-center rounded text-center p-5">
              <div class="flex flex-col">
                <div class="mb-2">
                  <span class="w-6 h-6 flex justify-center items-center bg-teal-lightest rounded-full mx-auto">
                    <i class="icon-v-stopwatch text-base text-teal-light" />
                  </span>
                </div>
                <span class="text-2xl text-black">
                  {{ decimalToDuration(getCallStats.AvgDurationInMin) }}
                  <div class="text-grey text-sm font-normal">
                    {{ $t('video.dashboard.index[6]') }}
                  </div>
                </span>
                <el-row
                  class="mt-4"
                  type="flex"
                  align="middle"
                  justify="center">
                  <i
                    v-if="getCallStats.AvgDurationTrend >= 0"
                    class="icon-v-trend-up text-green text-xs mr-2" />
                  <i
                    v-else
                    class="icon-v-trend-down text-red text-xs mr-2" />
                  <span class="text-sm text-black">
                    {{ `${getCallStats.AvgDurationTrend || 0}%` }}
                  </span>
                </el-row>
              </div>
            </div>
          </el-col>

          <!-- Avg Satisfaction Rating -->
          <el-col
            :xs="12"
            :sm="12"
            :lg="6">
            <div
              v-loading="getStatsLoading"
              class="h-full shadow flex justify-center items-center rounded text-center p-5">
              <div class="flex flex-col">
                <div class="mb-2">
                  <span class="w-6 h-6 flex justify-center items-center bg-red-lightest rounded-full mx-auto">
                    <i class="icon-v-smile text-base text-red-light" />
                  </span>
                </div>
                <span class="text-2xl text-black">
                  <span>
                    {{ getCallStats.AvgRating || 0 }}
                    <span class="text-base text-grey">
                      /  5
                    </span>
                  </span>
                  <div class="text-grey text-sm font-normal">
                    {{ $t('video.dashboard.index[7]') }}
                  </div>
                </span>
                <el-row
                  class="mt-4"
                  type="flex"
                  align="middle"
                  justify="center">
                  <i
                    v-if="getCallStats.AvgRatingTrend >= 0"
                    class="icon-v-trend-up text-green text-xs mr-2" />
                  <i
                    v-else
                    class="icon-v-trend-down text-red text-xs mr-2" />
                  <span class="text-sm text-black">
                    {{ `${getCallStats.AvgRatingTrend || 0}%` }}
                  </span>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="w-full mt-5">
        <Charts
          :loading="getStatsLoading"
          :selected-tab="activeName"
          :call-data="callchartdata"
          :duration-data="durationchartdata"
          :duration-options="durationChartOption"
          :chart-options="chartOptions"
          :rating-data="ratingchartdata" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
import Moment from 'moment';
import timeFormatter from '@/mixins/timeFormatter';
import Charts from './partials/Charts';
import Agents from './partials/Agents';

export default {

  name: 'Dashboard',

  components: { Charts, Agents },

  mixins: [timeFormatter],

  props: {
    weeks: {
      type: [Number, String],
      required: false,
      default: 1,
    },
  },

  data() {
    const self = this;
    return {

      weekFilter: [
        {
          value: 1,
          label: self.$t('date_labels.last_7_days'),
        },
        {
          value: 2,
          label: self.$t('date_labels.past_2_weeks'),
        },
        {
          value: 3,
          label: self.$t('date_labels.past_3_weeks'),
        },
        {
          value: 4,
          label: self.$t('date_labels.past_4_weeks'),
        },
      ],

      weeksValue: '',

      activeName: 'calls',

      chartOptions: {
        legend: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#ECECEC',
              },
              ticks: {
                min: 0,
                autoskip: true,
                autoSkipPadding: 10,
              },
              stacked: false,
              display: false,
              beginAtZero: true,
              scaleLabel: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: '#ECECEC',
              },
              ticks: {
                suggestedMax: 5,
                beginAtZero: true,
                precision: 0,
                callback(value) {
                  return value >= 1000 ? `${(value / 1000).toFixed((value / 1000) % 1 ? 1 : 0)}K` : value;
                },
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          borderWidth: 1,
        },
      },

      durationChartOption: {
        legend: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#ECECEC',
              },
              ticks: {
                min: 0,
                autoskip: true,
                autoSkipPadding: 10,
              },
              stacked: false,
              display: false,
              beginAtZero: true,
              scaleLabel: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: '#ECECEC',
              },
              ticks: {
                suggestedMax: 5,
                beginAtZero: true,
                precision: 0,
                callback(value) {
                  return value >= 1000 ? `${(value / 1000).toFixed((value / 1000) % 1 ? 1 : 0)}K` : value;
                },
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: item => `${self.$t('video.dashboard.index[8]')} ${Math.round(item.yLabel * 10) / 10} second/s`,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      agents: 'getAgents',
      getLoading: 'getLoading',
      getStatsLoading: 'getStatsLoading',
      getCallStats: 'getCallStats',
      tz: 'getTz',
    }),

    callchartdata() {
      const self = this;
      const callGraph = this.getCallStats.CallGraph;
      if (callGraph) {
        return {
          labels: callGraph.Label.map((dt) => {
            const d = this.getLocalDateTimeTz(dt,
                                              this.tz.timeZoneOffset,
                                              this.tz.timeZone,
                                              'MMMM DD, YYYY');
            return d;
          }),
          datasets: [
            {
              label: self.$t('video.performance_modal[5]'),
              data: callGraph.DroppedCalls,
              borderWidth: 0,
              radius: 0,
              pointHoverRadius: 3,
              borderColor: 'rgba(0, 60, 90, 0.8)',
              backgroundColor: 'rgba(0, 60, 90, 0.8)',
              pointBackgroundColor: 'rgba(0, 60, 90, 0.8)',
              pointBorderColor: '#FFF',
              spanGaps: true,
              lineTension: 0,
            }, {
              label: self.$t('video.performance_modal[4]'),
              data: callGraph.ConnectedCalls,
              borderWidth: 0,
              radius: 0,
              pointHoverRadius: 3,
              borderColor: 'rgba(31, 168, 217, 0.8)',
              backgroundColor: 'rgba(31, 168, 217, 0.8)',
              pointBackgroundColor: 'rgba(31, 168, 217, 0.8)',
              pointBorderColor: '#FFF',
              spanGaps: true,
              lineTension: 0,
            },
          ],
        };
      }
      return false;
    },

    durationchartdata() {
      const self = this;
      const durationGraph = this.getCallStats.DurationGraph;
      if (durationGraph) {
        return {
          labels: durationGraph.Label.map((dt) => {
            // getLocalDateTimeTz(date, offset, tz = '', format = 'D MMM YYYY hh:mm A') {
            const d = this.getLocalDateTimeTz(dt,
                                              this.tz.timeZoneOffset,
                                              this.tz.timeZone,
                                              'MMMM DD, YYYY');

            return d;
          }),
          datasets: [
            {
              label: self.$t('video.performance_modal[6]'),
              data: durationGraph.DurationCalls,
              radius: 0,
              pointHoverRadius: 3,
              borderColor: 'rgba(0, 33, 47, 0)',
              backgroundColor: 'rgba(37, 167, 216, 0.8)',
              pointBackgroundColor: '#25A7D8',
              pointBorderColor: '#FFF',
              spanGaps: true,
              borderWidth: 0,
              lineTension: 0,
            },
          ],
        };
      }
      return false;
    },

    ratingchartdata() {
      const self = this;
      const ratingGraph = this.getCallStats.RatingGraph;
      if (ratingGraph) {
        return {
          labels: ratingGraph.Label.map((dt) => {
            const d = this.getLocalDateTimeTz(dt,
                                              this.tz.timeZoneOffset,
                                              this.tz.timeZone,
                                              'MMMM DD, YYYY');

            return d;
          }),
          datasets: [
            {
              label: self.$t('video.performance_modal[7]'),
              data: ratingGraph.RatingCalls,
              radius: 0,
              pointHoverRadius: 3,
              borderColor: 'rgba(0, 33, 47, 0)',
              backgroundColor: 'rgba(37, 167, 216, 0.8)',
              pointBackgroundColor: '#25A7D8',
              pointBorderColor: '#FFF',
              spanGaps: true,
              borderWidth: 0,
              lineTension: 0,
            },
          ],
        };
      }
      return false;
    },
  },

  watch: {
    // '$route': 'fetchData'
    '$route': function fetch(to, from) {
      const { selected: prevSelected, modal: prevModal } = to.query;
      const { selected: nextSelected, modal: nextModal } = from.query;

      // prevent fetching if 'selected' params is changed
      if (prevSelected === nextSelected && prevModal === nextModal) {
        this.fetchData();
      }
    },
  },

  created() {
    this.fetchData();
    
    const { track } = this.$telemetry;
    track('dashboard_viewed', { channel: 'Video' });
  },

  methods: {
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },

    // Update query
    updateFilterQuery(query) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    },

    // Fetch Data
    async fetchData() {
      const weeks = parseInt(this.weeks, 10) || 1;

      this.weeksValue = (weeks > 4 || weeks < 1) ? 1 : weeks;

      try {
        await this.$store.dispatch('fetchCallStats', {
          weeks: this.weeksValue,
          utcOffset: this.tz.timeZoneOffset,
          timeZone: this.tz.timeZone,
        });

        await this.$store.dispatch('fetchAgents', {
          fields: ['UserId', 'Name', 'ProfileUrl', 'IsLoggedIn'],
        });
      } catch (err) {
        this.$showError(this, err);
      }
    },
  },
};
</script>

<style lang="scss">
  .el-card__body {
    height: 100%;
  }

  .el-tabs__content{
    height: 100%;
  }

  .el-row {
    width: 100%;
  }

  .right {
    float: right;
  }

  .left {
    float: left;
  }
</style>

<template>
  <el-dialog
    id="agentModal"
    :visible="visible"
    width="40%"
    :before-close="() => removeModalQuery()">
    <div class="flex flex-wrap">
      <div class="w-1/5">
        <el-tooltip
          effect="dark"
          :content="agent.IsLoggedIn ? $t('column_labels.online') : $t('column_labels.offline') "
          placement="bottom"
          :enterable="false">
          <AgentAvatar
            :url="agent.ProfileUrl"
            :status="agent.IsLoggedIn"
            size="lg"
            :disable-status="agent.LastLoginAt ? true : false" />
        </el-tooltip>
      </div>
      <div class="w-4/5 pl-5">
        <div v-if="agent.UserStatus !== 'I'">
          <h1
            v-if="agent.Name && agent.Name"
            class="text-black font-normal text-blue text-xl font-bold">
            {{ agent.Name }}
          </h1>
          <h1
            v-else
            class="text-black font-normal text-xl text-grey">
            {{ $t('video.dashboard.agents[0]') }}
          </h1>
          <time
            v-if="!agent.IsLoggedIn"
            class="text-xs text-grey roman"
            datetime="agent.LastLoginAt">
            {{ $t('video.performance_modal[0]') }} {{ timeFromNow(agent.LastLoginAt) }}
          </time>
          <span
            v-else
            class="text-xs text-green">
            {{ $t('video.agents.table[3]') }}
          </span>
        </div>
        <div>
          <h2 class="text-xs text-grey font-normal roman mt-5">
            {{ $t('fields.email') }}
          </h2>
          <p class="text-base text-black">
            {{ agent.Login }}
          </p>
        </div>
        <hr class="border-b mt-8 ml-0 w-1/3 border-grey-darker">
        <div class="flex">
          <div class="w-1/3">
            <h2 class="text-xs text-grey font-normal roman mt-5">
              {{ $t('column_labels.date_joined') }}
            </h2>
            <time
              v-if="agent.CreatedAt"
              class="text-base text-black"
              datetime="date.dateInvited">
              {{ formatDate(agent.CreatedAt, { utcOffset: tz.timeZoneOffset }) }}
            </time>
            <div
              v-else
              class="text-base text-red-lighter">
              {{ $t('video.profile_modal[0]') }}
            </div>
          </div>
        </div>
        <div class="mt-5">
          <div v-if="agent.UserStatus === 'A'">
            <el-button
              type="danger"
              size="small"
              plain
              @click="removeUser(agent.UserId)">
              {{ $t('actions.remove') }} {{ agent.Name || 'Agent' }}
            </el-button>
            <el-button
              size="small"
              plain
              @click="resetPassword(agent.UserId)">
              {{ $t('video.agents.table[5]') }}
            </el-button>
          </div>
          <div v-else>
            <el-button
              type="danger"
              size="small"
              plain
              @click="removeUser(agent.UserId)">
              {{ $t('video.agents.table[9]') }}
            </el-button>
            <el-button
              size="small"
              plain
              @click="resendInvite(agent.UserId)">
              {{ $t('video.agents.table[8]') }}
            </el-button>
          </div>
        </div>
        <hr class="border-b mt-8 ml-0 w-1/3 border-grey-darker">
        <div class="mt-8 hidden">
          <h2 class="text-xs text-grey font-normal roman mt-5">
            {{ $t('video.profile_modal[1]') }}
          </h2>
          <ul v-if="agent.apps && agent.apps.length > 0">
            <li
              v-for="(app, key) in agent.apps"
              :key="key">
              {{ app.name }}
            </li>
          </ul>
          <p
            v-else
            class="text-xs text-black">
            -
          </p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import timeFormatter from '@/mixins/timeFormatter';

import AgentAvatar from '@/components/AgentAvatar';

export default {
  name: 'AgentProfile',

  components: {
    AgentAvatar,
  },

  mixins: [timeFormatter],

  data() {
    return {
      visible: false,
    };
  },

  computed: mapGetters({
    agent: 'getCurrentAgent',
    agentList: 'getAgents',
    tz: 'getTz',
  }),

  watch: {
    '$route': 'toggleModal',
  },

  created() {
    this.toggleModal();
  },

  methods: {
    ...mapMutations({
      setSelectedAgent: 'SET_SELECTED_AGENT',
    }),

    // Update query
    removeModalQuery() {
      const { modal, ...noModal } = this.$route.query;
      this.$router.push({
        query: { ...noModal },
      });
    },

    // Dispatch reset password action
    resetPassword(id) {
      this.$store.dispatch('resetPassword', id).then((agent) => {
        this.$message.success(`${this.$t('video.agents.index[4]')} ${agent}`);
      })
        .catch((err) => {
          this.$showError(this, err);
        });
    },

    // Dispatch remove agent action
    removeUser(id) {
      const self = this;
      const agent = this.agentList.filter(agt => agt.UserId === id)[0];
      const agentName = agent.Name || agent.Login;
      this.$confirm(
        `${self.$t('video.agents.index[5]')} ${agentName}?`,
        self.$t('app_labels.warning'),
        {
          type: 'warning',
          center: true,
          cancelButtonText: self.$t('actions.cancel'),
          confirmButtonText: self.$t('actions.yes'),
        },
      )
        .then(() => {
          this.$store.dispatch('removeAgent', id)
            .then((at) => {
              // hide modal
              self.$message.success(self.$t('success.removed', { value: at }));
              const { selected, modal, ...query } = self.$route.query;
              self.$router.push({ query });
            }).catch((err) => {
              self.$showError(self, err);
            });
        });
    },

    // Resend invite to user
    resendInvite(id) {
      const self = this;
      const agent = this.agentList.filter(agt => agt.UserId === id)[0];
      const agentName = agent.Name || agent.Login;
      this.$confirm(
        `${self.$t('video.agents.index[7]')} ${agentName}?`,
        self.$t('app_labels.warning'),
        {
          type: 'warning',
          center: true,
          cancelButtonText: self.$t('actions.cancel'),
          confirmButtonText: self.$t('actions.yes'),
        },
      )
        .then(() => {
          self.$store.dispatch('resendInvite', id)
            .then(() => {
              // hide modal
              self.$message.success(`${agentName} ${self.$t('video.agents.index[6]')}`);
            }).catch((err) => {
              self.$showError(self, err);
            });
        });
    },

    // Toggle Modal based on 'modal' param
    toggleModal() {
      const modalQuery = this.$route.query.modal || '';
      const selectedAgentId = this.$route.query.selected || '';
      const modalName = this.$options.name;

      // initially set the agent detail based on agent list in store
      if (selectedAgentId && this.agentList.length) {
        const selectedAgent = this.agentList.filter(a => a.UserId === selectedAgentId);
        this.setSelectedAgent({ agent: selectedAgent[0] });
      }

      if (modalQuery === modalName) {
        this.visible = true;

        if (!this.agent.hasOwnProperty('UserId')) {
          this.fetchData();
        }
      } else {
        this.visible = false;
      }
    },

    // Fetch Agent Detail Data
    fetchData() {
      this.$store.dispatch('fetchAgentDetails', {
        agent: this.$route.query.selected,
      });
    },
  },
};
</script>

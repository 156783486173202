// import { Message } from 'element-ui';
import http from '../../utils/http';

// Initial state
const state = {
  tenant: {},
};

// Getters
const getters = {
  getTenant: st => st.tenant,
};

// Actions
const actions = {
  // Fetch tenant account
  fetchTenant: async ({ commit }, payload) => {
    const {
      accountId = '',
      uId = '',
    } = payload;

    try {
      const { data } = await http.get(`/tenants?uId=${uId}&accountId=${accountId}`);
      commit({ type: 'SET_TENANT', tenant: data });
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

// Mutations
const mutations = {
  SET_TENANT: (st, payload) => {
    st.tenant = payload.tenant;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

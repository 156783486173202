<template>
  <el-dialog
    id="performanceModal"
    :body="true"
    :visible.sync="visible"
    width="40%"
    :before-close="() => removeModalQuery()">
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <AgentAvatar
          v-if="agentInfo.Avatar"
          :status="agentInfo.IsLoggedIn"
          :url="agentInfo.Avatar"
          size="lg" />
        <AgentAvatar
          v-else
          :status="agentInfo.IsLoggedIn"
          size="lg" />
        <span class="ml-5">
          <h1
            v-if="agentInfo.Name"
            class="text-black font-normal text-black text-xl font-normal">
            {{ agentInfo.Name || agentInfo.Login }}
          </h1>
          <p
            v-if="agentInfo.IsLoggedIn"
            class="mt-2 text-xs text-green">
            {{ $t('video.agents.table[3]') }}
          </p>
          <p
            v-else-if="agentInfo.LastLoginAt"
            class="mt-2 text-xs">
            {{ $t('video.performance_modal[0]') }} {{ timeFromNow(agentInfo.LastLoginAt) }}
          </p>
          <p
            v-else
            class="mt-2 text-xs">
            –
          </p>
        </span>
      </div>
      <span>
        <RouterLink :to="{ query: { selected: agent, modal: 'AgentProfile' } }">
          <el-button type="primary">
            {{ $t('video.performance_modal[1]') }}
          </el-button>
        </RouterLink>
      </span>
    </div>

    <div class="mt-8 flex justify-end items-center">
      <span class="text-sm mr-2">
        {{ $t('video.performance_modal[2]') }}
      </span>
      <el-select
        v-model="weeksValue"
        :placeholder="$t('date_labels.weeks')"
        @change="val => fetchData(val)">
        <el-option
          v-for="item in weekFilter"
          :key="item.value"
          :label="item.label"
          :value="item.value" />
      </el-select>
    </div>

    <div class="mt-5 flex">
      <el-tabs
        v-model="activeTab"
        class="w-full">
        <!-- Call Graph -->
        <el-tab-pane
          :label="$t('column_labels.calls_made')"
          name="calls"
          :lazy="true">
          <LineChart
            v-if="callchartdata"
            :chart-data="callchartdata"
            class="h-48 w-full" />
          <div
            v-else
            class="p-12 text-small text-grey text-center">
            {{ $t('video.performance_modal[3]') }}
          </div>
        </el-tab-pane>

        <!-- Duration Call Graph -->
        <el-tab-pane
          :label="$t('column_labels.call_duration')"
          name="call-duration"
          :lazy="true">
          <LineChart
            v-if="durationchartdata"
            :chart-data="durationchartdata"
            class="h-48 w-full" />
          <div
            v-else
            class="p-12 text-small text-grey text-center">
            {{ $t('video.performance_modal[3]') }}
          </div>
        </el-tab-pane>

        <!-- User Score -->
        <el-tab-pane
          :label="$t('column_labels.call_rating')"
          name="user-score"
          :lazy="true">
          <LineChart
            v-if="ratingchartdata"
            :chart-data="ratingchartdata"
            class="h-48 w-full" />
          <div
            v-else
            class="p-12 text-small text-grey text-center">
            {{ $t('video.performance_modal[3]') }}
          </div>
        </el-tab-pane>
      </el-tabs>
      <div />
    </div>
    <div />
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Moment from 'moment';

import LineChart from '@/components/LineChart.vue';
import AgentAvatar from '@/components/AgentAvatar';

import timeFormatter from '@/mixins/timeFormatter';

export default {
  name: 'AgentPerformance',

  components: { AgentAvatar, LineChart },

  mixins: [timeFormatter],

  props: {
    weeks: {
      type: String,
      default: '1',
    },
    tz: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    const self = this;

    return {
      name: '',

      agent: null,

      visible: false,

      activeTab: 'calls',

      weekFilter: [{
        value: 1,
        label: self.$t('date_labels.this_week'),
      }, {
        value: 2,
        label: self.$t('date_labels.last_week'),
      }, {
        value: 3,
        label: self.$t('date_labels.past_2_weeks'),
      }, {
        value: 4,
        label: self.$t('date_labels.this_month'),
      }],

      weeksValue: '',
    };
  },

  computed: {
    agentInfo() {
      if (this.visible && !this.getStatsDetailLoading) {
        return {
          Avatar: this.getStatsDetail.Avatar,
          Name: this.getStatsDetail.Name,
          Login: this.getStatsDetail.Login,
          IsLoggedIn: this.getStatsDetail.IsLoggedIn,
          LastLoginAt: this.getStatsDetail.LastLoginAt,
        };
      }
      return false;
    },

    callchartdata() {
      const callGraph = this.getStatsDetail.CallGraph;

      if (callGraph) {
        const dataMap = callGraph.Label.reduce((acc, dt, index) => {
          const key = Moment.utc(dt).utcOffset(this.tz.timeZoneOffset).format('YYYY-MM-DD');
          let connectedTotal = 0;
          let droppedTotal = 0;

          if (Object.keys(acc[key] || {}).length) {
            connectedTotal = (acc[key].connectedCalls || 0) + (callGraph.ConnectedCalls[index]);
            droppedTotal = (acc[key].connectedCalls || 0) + (callGraph.DroppedCalls[index]);
          } else {
            connectedTotal = callGraph.ConnectedCalls[index];
            droppedTotal = callGraph.DroppedCalls[index];
          }
          return {
            ...acc,
            [`${key}`]: {
              connectedCalls: connectedTotal,
              droppedCalls: droppedTotal,
              date: Moment(dt).format('YYYY-MM-DD HH:mm'),
            },
          };
        }, {});

        // get day within range
        let endDate = '';
        let startDate = '';
        let timeType = '';

        switch (this.weeksValue) {
          case 1: {
            // this week
            endDate = Moment();
            startDate = Moment().subtract(1, 'week');
            timeType = 'days';
            break;
          }

          case 2: {
            // last week
            endDate = Moment();
            startDate = Moment().subtract(2, 'week');
            timeType = 'days';
            break;
          }

          case 3: {
            // past 2 weeks
            endDate = Moment();
            startDate = Moment().subtract(3, 'week');
            timeType = 'days';
            break;
          }

          case 4: {
            // this month
            endDate = Moment();
            startDate = Moment().subtract(4, 'week');
            timeType = 'days';
            break;
          }

          case 5: {
            // today
            endDate = Moment().endOf('day');
            startDate = Moment().startOf('day');
            timeType = 'hours';
            break;
          }

          default: {
            break;
          }
        }

        let dateRange = [];
        let dateIterator = new Date(startDate);
        while (dateIterator < new Date(endDate)) {
          dateRange = [...dateRange, Moment(dateIterator).format()];
          dateIterator = Moment(dateIterator).add(1, timeType);
        }

        const missingDateAppData = dateRange.reduce((acc, dt) => {
          const key = Moment(dt).format('YYYY-MM-DD');
          return {
            ...acc,
            [`${key}`]: {
              connectedCalls: 0,
              droppedCalls: 0,
              date: Moment(dt).format('YYYY-MM-DD HH:mm'),
            },
          };
        }, {});

        const mergeByDateTime = { ...missingDateAppData, ...dataMap };

        // sort date keys from ascending
        const sortedKeys = Object.keys(mergeByDateTime)
          .sort((a, b) => Moment(a).diff(Moment(b)));

        // remove keys that isnt within the date range
        const rangedDateKeys = sortedKeys
          .filter(dt => Moment(dt).isBetween(Moment(startDate), Moment(endDate), timeType, '[]'));

        const self = this;

        return {
          labels: rangedDateKeys.map(dt => Moment(dt).format(timeType === 'hours' ? 'HH:mm' : 'MMM DD')),
          datasets: [
            {
              label: self.$t('video.performance_modal[4]'),
              borderColor: '#25A7D8',
              borderWidth: 2,
              backgroundColor: 'rgba(37, 167, 216, 0.5)',
              pointBorderWidth: 0,
              pointBackgroundColor: '#25A7D8',
              radius: 2,
              data: rangedDateKeys.map(key => mergeByDateTime[key].connectedCalls),
            }, {
              label: self.$t('video.performance_modal[5]'),
              borderColor: '#00212F',
              borderWidth: 2,
              backgroundColor: 'rgba(0, 33, 47, 0.5)',
              pointBorderWidth: 0,
              pointBackgroundColor: '#00212F',
              radius: 2,
              data: rangedDateKeys.map(key => mergeByDateTime[key].droppedCalls),
            },
          ],
        };
      }
      return false;
    },

    durationchartdata() {
      const callGraph = this.getStatsDetail.DurationGraph;

      if (callGraph) {
        const dataMap = callGraph.Label.reduce((acc, dt, index) => {
          const key = Moment(dt).format('YYYY-MM-DD');
          let durationTotal = 0;

          if (Object.keys(acc[key] || {}).length) {
            durationTotal = (acc[key].durationCalls || 0) + (callGraph.DurationCalls[index]);
          } else {
            durationTotal = callGraph.DurationCalls[index];
          }
          return {
            ...acc,
            [`${key}`]: {
              durationCalls: durationTotal,
              date: Moment(dt).format('YYYY-MM-DD HH:mm'),
            },
          };
        }, {});

        // get day within range
        let endDate = '';
        let startDate = '';
        let timeType = '';

        switch (this.weeksValue) {
          case 1: {
            // this week
            endDate = Moment();
            startDate = Moment().subtract(1, 'week');
            timeType = 'days';
            break;
          }

          case 2: {
            // last week
            endDate = Moment();
            startDate = Moment().subtract(2, 'week');
            timeType = 'days';
            break;
          }

          case 3: {
            // past 2 weeks
            endDate = Moment();
            startDate = Moment().subtract(3, 'week');
            timeType = 'days';
            break;
          }

          case 4: {
            // this month
            endDate = Moment();
            startDate = Moment().subtract(4, 'week');
            timeType = 'days';
            break;
          }

          case 5: {
            // today
            endDate = Moment().endOf('day');
            startDate = Moment().startOf('day');
            timeType = 'hours';
            break;
          }

          default: {
            break;
          }
        }

        let dateRange = [];
        let dateIterator = new Date(startDate);
        while (dateIterator < new Date(endDate)) {
          dateRange = [...dateRange, Moment(dateIterator).format()];
          dateIterator = Moment(dateIterator).add(1, timeType);
        }

        const missingDateAppData = dateRange.reduce((acc, dt) => {
          const key = Moment(dt).format('YYYY-MM-DD');
          return {
            ...acc,
            [`${key}`]: {
              durationCalls: 0,
              date: Moment(dt).format('YYYY-MM-DD HH:mm'),
            },
          };
        }, {});

        const mergeByDateTime = { ...missingDateAppData, ...dataMap };

        // sort date keys from ascending
        const sortedKeys = Object.keys(mergeByDateTime)
          .sort((a, b) => Moment(a).diff(Moment(b)));

        // remove keys that isnt within the date range
        const rangedDateKeys = sortedKeys
          .filter(dt => Moment(dt).isBetween(Moment(startDate), Moment(endDate), timeType, '[]'));

        const self = this;

        return {
          labels: rangedDateKeys.map(dt => Moment(dt).format(timeType === 'hours' ? 'HH:mm' : 'MMM DD')),
          datasets: [
            {
              label: self.$t('video.performance_modal[6]'),
              borderColor: '#25A7D8',
              borderWidth: 2,
              backgroundColor: 'rgba(37, 167, 216, 0.5)',
              pointBorderWidth: 0,
              pointBackgroundColor: '#25A7D8',
              radius: 2,
              data: rangedDateKeys.map(key => mergeByDateTime[key].durationCalls),
            },
          ],
        };
      }
      return false;
    },

    ratingchartdata() {
      const callGraph = this.getStatsDetail.RatingGraph;

      if (callGraph) {
        const dataMap = callGraph.Label.reduce((acc, dt, index) => {
          const key = Moment(dt).format('YYYY-MM-DD');
          let ratingTotal = 0;

          if (Object.keys(acc[key] || {}).length) {
            ratingTotal = (acc[key].ratingCalls || 0) + (callGraph.RatingCalls[index]);
          } else {
            ratingTotal = callGraph.RatingCalls[index];
          }
          return {
            ...acc,
            [`${key}`]: {
              ratingCalls: ratingTotal,
              date: Moment(dt).format('YYYY-MM-DD HH:mm'),
            },
          };
        }, {});

        // get day within range
        let endDate = '';
        let startDate = '';
        let timeType = '';

        switch (this.weeksValue) {
          case 1: {
            // this week
            endDate = Moment();
            startDate = Moment().subtract(1, 'week');
            timeType = 'days';
            break;
          }

          case 2: {
            // last week
            endDate = Moment();
            startDate = Moment().subtract(2, 'week');
            timeType = 'days';
            break;
          }

          case 3: {
            // past 2 weeks
            endDate = Moment();
            startDate = Moment().subtract(3, 'week');
            timeType = 'days';
            break;
          }

          case 4: {
            // this month
            endDate = Moment();
            startDate = Moment().subtract(4, 'week');
            timeType = 'days';
            break;
          }

          case 5: {
            // today
            endDate = Moment().endOf('day');
            startDate = Moment().startOf('day');
            timeType = 'hours';
            break;
          }

          default: {
            break;
          }
        }

        let dateRange = [];
        let dateIterator = new Date(startDate);
        while (dateIterator < new Date(endDate)) {
          dateRange = [...dateRange, Moment(dateIterator).format()];
          dateIterator = Moment(dateIterator).add(1, timeType);
        }

        const missingDateAppData = dateRange.reduce((acc, dt) => {
          const key = Moment(dt).format('YYYY-MM-DD');
          return {
            ...acc,
            [`${key}`]: {
              ratingCalls: 0,
              date: Moment(dt).format('YYYY-MM-DD HH:mm'),
            },
          };
        }, {});

        const mergeByDateTime = { ...missingDateAppData, ...dataMap };

        // sort date keys from ascending
        const sortedKeys = Object.keys(mergeByDateTime)
          .sort((a, b) => Moment(a).diff(Moment(b)));

        // remove keys that isnt within the date range
        const rangedDateKeys = sortedKeys
          .filter(dt => Moment(dt).isBetween(Moment(startDate), Moment(endDate), timeType, '[]'));

        const self = this;
        return {
          labels: rangedDateKeys.map(dt => Moment(dt).format(timeType === 'hours' ? 'HH:mm' : 'MMM DD')),
          datasets: [
            {
              label: self.$t('video.performance_modal[7]'),
              borderColor: '#25A7D8',
              borderWidth: 2,
              backgroundColor: 'rgba(37, 167, 216, 0.5)',
              pointBorderWidth: 0,
              pointBackgroundColor: '#25A7D8',
              radius: 2,
              data: rangedDateKeys.map(key => mergeByDateTime[key].ratingCalls),
            },
          ],
        };
      }
      return false;
    },

    ...mapGetters({
      getStatsDetailLoading: 'getAgentStatsDetailLoading',
      getStatsDetail: 'getAgentStatsDetail',
    }),
  },

  watch: {
    '$route': 'toggleModal',
  },

  created() {
    this.toggleModal();
  },

  methods: {

    // Update query
    removeModalQuery() {
      const { modal, ...noModal } = this.$route.query;
      this.$router.push({
        query: { ...noModal },
      });
    },

    // Toggle Modal based on 'modal' param
    toggleModal() {
      const modalQuery = this.$route.query.modal || '';
      const modalName = this.$options.name;

      if (modalQuery === modalName) {
        this.visible = true;
        this.fetchData();
      } else {
        this.visible = false;
        this.activeTab = 'calls';
      }
    },

    // Fetch Performance Data
    fetchData(weeks = parseInt(this.weeks, 10) || 1) {
      this.weeksValue = (weeks > 4 || weeks < 1) ? 1 : weeks;

      const { selected: agent } = this.$route.query;
      this.agent = agent;

      this.$store.dispatch('fetchAgentStatsDetail', {
        weeks,
        agent,
      });
    },
  },
};
</script>

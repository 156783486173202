<template>
  <form class="relative">
    <div
      v-if="warning && tenant.DataRetentionHrs"
      class="border rounded border-orange bg-orange-lightest p-3 mb-3">
      <div class="flex mb-3">
        <div class="w-4/5">
          <span class="material-icons-outlined text-base">
            shield
          </span>
          <span class="material-icons-outlined text-grey-dark text-base">
            add
          </span>
          <span class="material-icons-outlined text-base">
            group
          </span>
        </div>
        <div class="w-1/5 text-right">
          <span
            class="material-icons-outlined text-grey text-base cursor-pointer"
            @click="warning = false">
            close
          </span>
        </div>
      </div>
      <h4>{{ $t('video.privacy_protection_title') }}</h4>
      <p class="mt-3 text-xs">
        {{ $t('video.privacy_protection_desc') }} <b> {{ getDataRetention(tenant.DataRetentionHrs) }}</b>
      </p>
    </div>
    <div class="flex p-4 pb-8 -mb-4 rounded-t bg-grey-lightest">
      <div class="w-full">
        <!-- date range Select -->
        <div>
          <span class="text-xs block mb-2">{{ $t('column_labels.date_range') }}</span>
          <el-date-picker
            v-model="dates"
            prefix-icon="el-icon-date"
            size="small"
            format="dd MMM yyyy"
            value-format="yyyy-MM-dd"
            type="daterange"
            :picker-options="dateOptions"
            class="vi-datepicker"
            range-separator="–"
            unlink-panels
            :start-placeholder="$t('date_labels.start_date')"
            :end-placeholder="$t('date_labels.end_date')"
            @change="v => $emit('update-filter', { from: v[0], to: v[1] })" />
        </div>
        <!-- <h3 class="font-medium text-black text-sm mb-5">
          {{ $t('date_labels.date_filter') }}
        </h3> -->
        <!-- <div class="px-1">
          <span class="text-xs block mb-2">{{ $t('date_labels.date_filter') }}</span>
          <el-select
            :value="dateFilter"
            size="small"
            class="w-full"
            filterable
            @change="updateDateFilter">
            <el-option
              v-for="(item,i) in dateFilters"
              :key="i"
              :label="item.label"
              :value="item.id" />
          </el-select>
        </div> -->
      </div>
    </div>
    <div class="flex flex-col rounded p-4 w-full justify-between text-black bg-grey-lighter">
      <h3 class="font-medium text-black text-sm mb-5">
        {{ $t('app_labels.filters') }}
      </h3>
      <div>
        <!-- subAccountId Select -->
        <div class="px-1">
          <span class="text-xs block mb-2">{{ $tc('column_labels.agent', 2) }}</span>
          <el-select
            :value="agent"
            size="small"
            class="w-full"
            :placeholder="$t('video.call_logs.filters[0]')"
            filterable
            @change="v => $emit('update-filter', { agent: v })">
            <el-option
              v-for="item in [{Name:$t('video.call_logs.filters[1]'), UserId:''}, ...agentList]"
              :key="item.value"
              :label="item.Name || item.Login"
              :value="item.UserId" />
          </el-select>
        </div>
        <div class="mt-4 px-1">
          <span class="text-xs block mb-2">{{ $t('column_labels.status') }}</span>
          <el-select
            :value="status"
            :placeholder="$t('video.call_logs.filters[2]')"
            size="small"
            class="w-full"
            @change="v => $emit('update-filter', { status: v })">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="$t(`video.call_status.${item.name}`)"
              :value="item.value" />
          </el-select>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import dateFilter from '@/mixins/dateFilter';

export default {
  mixins: [dateFilter],
  props: {
    dateOptions: {
      type: Array,
      default: () => [],
    },

    defaultDateFilter: {
      default: () => [],
      type: Array,
    },

    agent: {
      default: '',
      type: String,
    },

    agentList: {
      default: () => [],
      type: Array,
    },

    status: {
      default: '',
      type: String,
    },

    statusList: {
      default: () => [],
      type: Array,
    },

    tenant: {
      type: Array,
      default: () => ({ DataRetentionHrs: null }),
    },
  },

  data() {
    const self = this;
    return {
      dates: [],
      dateFilters: [
        { id: 'last_week', label: self.$t('date_labels.last_week') },
        { id: 'last_month', label: self.$t('date_labels.last_month') },
        { id: 'last_3months', label: self.$t('date_labels.last_3_months') },
      ],
      warning: true,
      dateFilter: 'last_week',
    };
  },

  created() {
    this.dates = this.defaultDateFilter;
  },

  methods: {
    // updateDateFilter(v) {
    //   this.dateFilter = v;
    //   const { to, from } = this.defaultDate(v);
    //   this.$emit('update-filter', { to, from });
    // },

    getDataRetention(hrs) {
      if (!hrs) return '-';

      if (hrs <= 24) {
        return `${hrs} ${this.$tc('time_labels.hour', 2)}`;
      }

      return `${Math.round(hrs / 24)} ${this.$tc('column_labels.days', 2)}`;
    },
  },
};
</script>

<style scoped lang="scss">
.vi-datepicker {
  width: 100% !important;
}
</style>

<template>
  <el-table
    v-loading="loading && true"
    :data="data"
    style="width: 100%"
    size="small"
    :row-class-name="tableRowClassName"
    @row-click="conference => $emit('show-detail', conference)">
    <template slot="empty">
      <div class="text-center p-10 text-xs text-black leading-loose">
        <h1 class="text-black text-base mb-5 font-semibold">
          {{ $t('video.call_logs.table[0]') }}
        </h1>
        <span class="text-sm text-grey block mb-5">
          {{ $t('video.dashboard.charts[5]') }}
        </span>
      </div>
    </template>
    <!-- Inbound/Outbound icon -->
    <el-table-column
      width="35px"
      prop="phone">
      <template slot-scope="phoneScope">
        <img
          v-if="phoneScope.row.Origin === 'Inbound'"
          src="../../../assets/incoming-call.svg"
          alt="inbound call"
          class="block">
        <img
          v-else-if="phoneScope.row.Origin === 'Outbound'"
          src="../../../assets/outgoing-call.svg"
          alt="outbound call"
          class="block">
      </template>
    </el-table-column>

    <!-- Phone Column -->
    <el-table-column
      prop="phone"
      show-overflow-tooltip
      :label="$t('column_labels.phone_no')">
      <template slot-scope="phoneScope">
        <span
          v-if="phoneScope.row.UserB_MSISDN"
          class="text-xs truncate"
          v-html="$sanitize(highlight(formatPhone(phoneScope.row.UserB_MSISDN).toString(), search))" />
        <span
          v-if="phoneScope.row.Origin === 'Inbound' || !phoneScope.row.UserB_MSISDN"
          class="text-xs truncate">{{ phoneScope.row.CallId }}</span>
      </template>
    </el-table-column>

    <!-- Call Reference Column -->
    <el-table-column
      prop="callId"
      show-overflow-tooltip
      :label="$t('column_labels.call_reference')">
      <template slot-scope="callIdScope">
        <span
          v-if="callIdScope.row.Reference"
          class="text-xs truncate"
          v-html="$sanitize(highlight(callIdScope.row.Reference, search))" />
        <span
          v-else
          class="text-xs text-grey-dark truncate">
          {{ $t('app_labels.none') }}
        </span>
      </template>
    </el-table-column>

    <!-- Agent Column -->
    <el-table-column
      prop="agent"
      show-overflow-tooltip
      :label="$tc('column_labels.agent', 1)">
      <template slot-scope="agentScope">
        <span
          v-if="agentScope.row.Name || agentScope.row.Login"
          class="text-xs truncate">
          {{ agentScope.row.Name || agentScope.row.Login }}
        </span>
        <span
          v-else
          class="text-xs text-grey truncate">
          {{ $t('video.call_logs.table[1]') }}
        </span>
      </template>
    </el-table-column>

    <!-- Status Column -->
    <el-table-column
      prop="status"
      width="125px"
      show-overflow-tooltip
      align="left"
      :label="$t('column_labels.status')">
      <template slot-scope="statusScope">
        <div
          v-if="statusScope.row.Status === 'E'"
          class="text-xs inline-block font-bold font-bold text-black rounded-sm vi-tag">
          {{ getStatus(statusScope.row.Status) }}
        </div>
        <div
          v-else-if="statusScope.row.Status === 'S' || statusScope.row.Status === 'P'"
          class="text-xs inline-block font-bold font-bold text-black rounded-sm vi-tag">
          {{ getStatus(statusScope.row.Status) }}
        </div>
        <div
          v-else-if="statusScope.row.Status === 'W'"
          class="text-xs inline-block font-bold font-bold text-black rounded-sm vi-tag">
          {{ getStatus(statusScope.row.Status) }}
        </div>
        <div
          v-else-if="statusScope.row.Status === 'C' || statusScope.row.Status === 'X' || statusScope.row.Status === 'U'"
          class="text-xs inline-block font-bold font-bold text-black rounded-sm vi-tag">
          {{ getStatus(statusScope.row.Status) }}
        </div>
        <div
          v-else
          class="text-xs inline-block font-bold font-bold text-black rounded-sm vi-tag">
          {{ getStatus(statusScope.row.Status) }}
        </div>
      </template>
    </el-table-column>

    <!-- App id Column -->
    <el-table-column
      prop="callApp"
      show-overflow-tooltip
      :label="$t('column_labels.queue')">
      <template slot-scope="callAppScope">
        <span
          v-if="callAppScope.row.AppId_Default !== null"
          class="text-xs text-black truncate">
          {{ `app-${callAppScope.row.AppId || 0}` }}
        </span>
        <span
          v-else
          class="text-xs text-grey truncate">—</span>
      </template>
    </el-table-column>

    <!-- Duration Column -->
    <el-table-column
      prop="callDuration"
      show-overflow-tooltip
      align="center"
      :label="$t('column_labels.call_duration')">
      <template slot-scope="callDurationScope">
        <span
          v-if="callDurationScope.row.Duration <= 0"
          class="text-xs text-grey truncate">
          00:00
        </span>
        <span
          v-else
          class="text-xs truncate">
          {{ FormatDuration(callDurationScope.row.Duration) }}
        </span>
      </template>
    </el-table-column>

    <!-- Date Created Column -->
    <el-table-column
      class="text-right"
      prop="date"
      show-overflow-tooltip
      align="right"
      :label="getDateHeader(tz.timeZone, $t('column_labels.date'))">
      <template slot-scope="dateScope">
        <span class="text-xs truncate">
          {{
            getLocalDateTimeTz(dateScope.row.CreatedAt,
                               tz.timeZoneOffset,
                               '',
                               'MMM DD, YYYY hh:mm A',
            )
          }}
        </span>
      </template>
    </el-table-column>

    <!-- Dropdown -->
    <el-table-column width="50">
      <template>
        <el-dropdown class="flex justify-end">
          <span class="el-dropdown-link flex items-center">
            <i class="material-icons text-base cursor-pointer hover:text-blue hidden">
              more_vert
            </i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>{{ $t('video.call_logs.table[2]') }}</el-dropdown-item>
            <el-dropdown-item class="text-blue">
              {{ $t('video.call_logs.table[3]') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex';
import phoneFormatter from '@/mixins/phoneFormatter';
import timeFormatter from '@/mixins/timeFormatter';
import statusList from '@/mixins/status';
import highlight from '@/mixins/highlight';

export default {
  name: 'Table',

  mixins: [
    phoneFormatter,
    timeFormatter,
    statusList,
    highlight,
  ],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: () => [],
    },

    search: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters({
      tz: 'getTz',
    }),
  },

  methods: {
    // Add class name for row depending on status
    tableRowClassName({ row }) {
      let modifier = '';
      if (!row.Duration) {
        modifier = 'error-row';
      } else {
        modifier = 'cursor-pointer';
      }
      return modifier;
    },

  },
};
</script>

<template>
  <div
    v-if="size === 'lg'"
    class="h-24 w-24 relative block flex-no-shrink">
    <img
      v-if="url"
      :src="url"
      alt=""
      class="avatar bg-cover bg-center shadow h-full w-full absolute pin bg-grey-lighter rounded-full">
    <img
      v-else
      src="../assets/avatar-default.svg"
      alt=""
      class="avatar bg-cover bg-center shadow h-full w-full absolute pin bg-grey-lighter rounded-full">
    <div v-if="!disableStatus">
      <span
        v-if="status"
        class="shadow rounded-full w-3 h-3 bg-green-light absolute pin-t pin-r m-2 border-solid border-white border-2" />
      <span
        v-else
        class="shadow rounded-full w-3 h-3 bg-red-light absolute pin-t pin-r m-2 border-solid border-white border-2" />
    </div>
  </div>
  <div
    v-else-if="size === 'base'"
    class="h-16 w-16 xl:h-24 xl:w-24 relative block flex-no-shrink">
    <img
      v-if="url"
      :src="url"
      alt=""
      class="avatar bg-cover bg-center shadow h-full w-full absolute pin bg-grey-lighter rounded-full">
    <img
      v-else
      src="../assets/avatar-default.svg"
      alt=""
      class="avatar bg-cover bg-center shadow h-full w-full absolute pin bg-grey-lighter rounded-full">
    <div v-if="!disableStatus">
      <span
        v-if="status"
        class="shadow rounded-full m-1 xl:m-2 w-2 h-2 xl:w-3 xl:h-3 bg-green-light absolute pin-t pin-r border-solid border-white border-2" />
      <span
        v-else
        class="shadow rounded-full m-1 xl:m-2 w-2 h-2 xl:w-3 xl:h-3 bg-red-light absolute pin-t pin-r border-solid border-white border-2" />
    </div>
  </div>
  <div
    v-else-if="size === 'md'"
    class="h-16 w-16 relative block flex-no-shrink">
    <img
      v-if="url"
      :src="url"
      alt=""
      class="avatar bg-cover bg-center shadow h-full w-full absolute pin bg-grey-lighter rounded-full">
    <img
      v-else
      src="../assets/avatar-default.svg"
      alt=""
      class="avatar bg-cover bg-center shadow h-full w-full absolute pin bg-grey-lighter rounded-full">
    <template v-if="!disableStatus">
      <span
        v-if="status"
        class="avatar__status avatar__status--md shadow rounded-full w-2 h-2 bg-green-light border-solid border-white border-2" />
      <span
        v-else
        class="avatar__status avatar__status--md shadow rounded-full w-2 h-2 bg-red-light border-solid border-white border-2" />
    </template>
  </div>
  <div
    v-else-if="size === 'sm'"
    class="h-8 w-8 relative block flex-no-shrink">
    <img
      v-if="url"
      :src="url"
      alt=""
      class="avatar bg-cover bg-center shadow h-full w-full absolute pin bg-grey-lighter rounded-full">
    <img
      v-else
      src="../assets/avatar-default.svg"
      alt=""
      class="avatar bg-cover bg-center shadow h-full w-full absolute pin bg-grey-lighter rounded-full">
    <div v-if="!disableStatus">
      <span
        v-if="status"
        class="shadow rounded-full w-2 h-2 bg-green-light absolute pin-t pin-r border-solid border-white border-2" />
      <span
        v-else
        class="shadow rounded-full w-2 h-2 bg-red-light absolute pin-t pin-r border-solid border-white border-2" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgentAvatar',
  props: {
    url: {
      type: String,
      default: '',
    },
    status: Boolean,
    size: {
      type: String,
      default: 'base', // sm, md, base, lg
    },
    disableStatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  object-fit: cover;
  position: relative;

  &__status {
    position: absolute;

    &--md {
      top: 5px;
      right: 5px;
    }
  }
}
</style>

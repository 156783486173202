<template>
  <div class="flex">
    <!-- Week Select -->
    <span class="text-white pt-2">
      {{ tz.timeZone }}&nbsp;
    </span>
    <el-select
      v-model="week"
      :disabled="loading"
      :placeholder="$t('video.performance.filters[0]')"
      size="small"
      class="ml-3"
      @change="val => $emit('update-filter', { weeks: val })">
      <el-option
        v-for="item in weekOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value" />
    </el-select>

    <!-- Agent Select -->
    <el-select
      v-model="agent"
      :disabled="loading"
      :placeholder="$t('video.performance.filters[1]')"
      size="small"
      class="ml-3"
      @change="val => $emit('update-filter', { agent: val })">
      <el-option
        v-for="item in [{Name: $t('video.performance.filters[2]'), UserId:''}, ...agentOptions]"
        :key="item.value"
        :label="item.Name || item.Login"
        :value="item.UserId" />
    </el-select>

    <!-- Sort Select -->
    <el-select
      v-model="order"
      :disabled="loading"
      :placeholder="$t('video.performance.filters[3]')"
      size="small"
      class="ml-3"
      @change="val => $emit('update-filter', { order: val })">
      <el-option
        v-for="item in orderOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value" />
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Filters',

  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    week: {
      required: true,
      type: String,
    },
    weekOptions: {
      required: true,
      type: Array,
    },
    agent: {
      required: true,
      type: String,
    },
    agentOptions: {
      required: true,
      type: Array,
    },
    order: {
      required: true,
      type: String,
    },
    orderOptions: {
      required: true,
      type: Array,
    },
  },

  computed: {
    ...mapGetters({
      tz: 'getTz',
    }),
  },
};
</script>

import { Loading } from 'element-ui';
import http from '../../utils/http';

// Initial state
const state = {
  isLoading: false,
  subscriptionEnd: null,
  agents: [],
  currentAgent: {},
  isAgentStatsLoading: false,
  agentsStats: [],
  agentStatsDetail: {},
  isStatsDetailLoading: false,
  totalAgentsCount: 0,
};

// Getters
const getters = {
  getCurrentAgent: st => st.currentAgent,
  getAgents: st => st.agents,
  getTotal: st => st.agents.length,
  getAvailableTotal: st => st.agents.filter(agent => agent.IsLoggedIn).length,
  getLoading: st => st.isLoading,
  getSubscriptionEnd: st => st.subscriptionEnd,
  getAgentStatsLoading: st => st.isAgentStatsLoading,
  getAgentsStats: st => st.agentsStats,
  getAgentStatsDetail: st => st.agentStatsDetail,
  getAgentStatsDetailLoading: st => st.isStatsDetailLoading,
  getTotalAgentsCount: st => st.totalAgentsCount,
};

// Actions
const actions = {

  // Fetch agent statistics detail
  fetchAgentStatsDetail: async ({ commit, state: st }, payload) => {
    const {
      weeks, agent, utcOffset, timeZone,
    } = payload;

    commit({ type: 'SET_AGENT_STATS_DETAIL_LOADING', bool: true });
    const modal = document.querySelector('#performanceModal .el-dialog');
    const loading = Loading.service({ target: modal });

    try {
      let { data } = await http.get(`/agents/performances/${agent}`, { weeks, utcOffset, timeZone });

      if (!data) {
        const { Name, AgentId, Avatar } = st.agentStatsDetail;
        data = {
          ...data, Name, AgentId, Avatar,
        };
      }

      commit({ type: 'SET_AGENT_STATS_DETAIL', data });
      commit({ type: 'SET_AGENT_STATS_DETAIL_LOADING', bool: false });

      loading.close();

      return data;
    } catch (err) {
      loading.close();
      commit({ type: 'SET_AGENT_STATS_DETAIL_LOADING', bool: false });
      return Promise.reject(err);
    }
  },

  // Fetch agent statistics
  fetchAgentsStats: async ({ commit }, payload) => {
    const {
      weeks, order, utcOffset, timeZone,
    } = payload;
    let { tenant, agent } = payload;

    tenant = tenant || 0;
    agent = agent || '';

    commit({ type: 'SET_AGENT_STATS_LOADING', bool: true });

    try {
      const { data } = await http.get('/agents/performances', {
        weeks, tenant, order, agent, utcOffset, timeZone,
      });

      commit({ type: 'SET_AGENT_STATS', agents: data });
      commit({ type: 'SET_AGENT_STATS_LOADING', bool: false });
      return data;
    } catch (err) {
      commit({ type: 'SET_AGENT_STATS_LOADING', bool: false });
      return Promise.reject(err);
    }
  },

  // Fetch agent detail
  fetchAgentDetails: async ({ commit }, payload) => {
    const { agent: agentId } = payload;

    const modal = document.querySelector('#agentModal .el-dialog');
    const loading = Loading.service({
      target: modal,
    });

    try {
      const { data } = await http.get(`/agents/${agentId}`);

      commit({ type: 'SET_SELECTED_AGENT', agent: data });
      loading.close();
      return data;
    } catch (err) {
      loading.close();
      return Promise.reject(err);
    }
  },

  // Fetch agent list from server
  fetchAgents: async ({ commit }, payload = {}) => {
    const f = payload.fields ? payload.fields.toString() : '';
    const { q, status, app } = payload;
    try {
      commit({
        type: 'SET_LOADING',
        bool: true,
      });

      const { data } = await http.get(`/agents?fields=${f}&q=${q || ''}&status=${status || ''}&app=${app || ''}`);

      commit({ type: 'FETCH_AGENTS', agents: data });
      commit({ type: 'SET_LOADING', bool: false });

      return data;
    } catch (err) {
      commit({ type: 'SET_LOADING', bool: false });
      return Promise.reject(err);
    }
  },

  // Fetch date for subscribtion end in server
  fetchSubscriptionEnd: async ({ commit }) => {
    try {
      commit({ type: 'FETCH_SUBSCRIPTION_END', date: '2019-07-07' });
    } catch (err) {
      console.log('ERROR FETCHING SUBSCRIPTION END', err); // eslint-disable-line
    }
  },

  // Send reset password access to agent
  resetPassword: async ({ state: st }, id) => {
    const agent = st.agents.filter(agt => agt.UserId === id)[0];
    try {
      await http.post(`/agents/${agent.UserId}/reset-password`, {
        email: agent.Login,
      });

      return agent.Name || agent.Login;

      // Message.success({ message: `Password reset link email has been sent to ${agent.Name || agent.Login}` });
    } catch (err) {
      // Message.error({
      //   message: `Failed to reset link email to ${agent.Name || agent.Login}`,
      //   showClose: true,
      // });
      return Promise.reject(err);
    }
  },

  // Remove agent
  removeAgent: async ({ commit, state: st }, id) => {
    const agent = st.agents.filter(agt => agt.UserId === id)[0];
    const agentName = agent.Name || agent.Login;


    const resetAgent = st.agents; // If request fails set state.agents to original data
    commit({ type: 'FETCH_AGENTS', agents: st.agents.filter(agt => agt.UserId !== id) }); // Remove agent from UI
    commit({ type: 'SET_TOTAL_AGENTS_COUNT', totalAgentsCount: st.totalAgentsCount - 1 });

    try {
      await http.del(`/agents/${agent.UserId}`);

      return agentName;

      // Message.success({ message: `${agentName} has been removed` });
    } catch (err) {
      // Message.error({
      //   message: `Failed to remove ${agentName}`,
      //   showClose: true,
      // });
      commit({ type: 'FETCH_AGENTS', agents: resetAgent });
      commit({ type: 'SET_TOTAL_AGENTS_COUNT', totalAgentsCount: st.totalAgentsCount + 1 });

      return Promise.reject(err);
    }
  },

  // Send invite to agent
  inviteAgent: async ({ dispatch, commit, state: st }, payload) => {
    const { email } = payload;
    const reqBody = {
      ...payload,
      email: payload.email.replaceAll(' ', ''),
    };

    try {
      const { data } = await http.post('/agents', reqBody);
      const { userId: UserId, email: Login } = data;

      if (!payload.reinvite) {
        commit({ type: 'ADD_AGENT', agent: { UserId, Login, CreatedAt: new Date() } });
        commit({ type: 'SET_TOTAL_AGENTS_COUNT', totalAgentsCount: st.totalAgentsCount + 1 });
      }

      await dispatch('fetchAgents');
      // Message.success({ message: `${email} has been invited` });

      return data;
    } catch (err) {
      commit({ type: 'SET_TOTAL_AGENTS_COUNT', totalAgentsCount: st.totalAgentsCount - 1 });
      return Promise.reject(err);
    }
  },

  // Resend agent invite
  resendInvite: async ({ dispatch, state: st }, id) => {
    const agent = st.agents.filter(agt => agt.UserId === id)[0];

    try {
      await dispatch('inviteAgent', {
        email: agent.Login,
        reinvite: true,
      });

      return agent.Login;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  // Update agent app
  updateAgentApp: async ({ dispatch, state: st }, payload) => { // eslint-disable-line
    const { appId, userId } = payload;

    // cancel action if either values are undefined or null
    if (!appId || !userId) return;

    try {
      await http.post('/video/agents/actions/assign-application', {
        appId: appId.toString(),
        userId,
      });
      await dispatch('fetchAgents');
      // eslint-disable-next-line consistent-return
      return 1;
    } catch (err) {
      // Message.error({
      //   message: err.message,
      //   showClose: true,
      // });
      // eslint-disable-next-line consistent-return
      return Promise.reject(err);
    }
  },

  fetchTotalAgentsCount: async ({ commit }) => {
    commit({
      type: 'SET_LOADING',
      bool: true,
    });

    try {
      const { data: { count } } = await http.get('/agents/count');
      commit({ type: 'SET_TOTAL_AGENTS_COUNT', totalAgentsCount: count });

      return count;
    } catch (err) {
      throw err;
    } finally {
      commit({
        type: 'SET_LOADING',
        bool: false,
      });
    }
  },
};

// Mutations
const mutations = {
  FETCH_SUBSCRIPTION_END: (st, payload) => { st.subscriptionEnd = payload.date; },

  ADD_AGENT: (st, payload) => { st.agents.unshift(payload.agent); },

  FETCH_AGENTS: (st, payload) => { st.agents = payload.agents; },

  SET_SELECTED_AGENT: (st, payload) => { st.currentAgent = payload.agent; },

  SET_LOADING: (st, payload) => { st.isLoading = payload.bool; },

  SET_AGENT_STATS_LOADING: (st, payload) => { st.isAgentStatsLoading = payload.bool; },

  SET_AGENT_STATS: (st, payload) => { st.agentsStats = payload.agents; },

  SET_AGENT_STATS_DETAIL: (st, payload) => { st.agentStatsDetail = payload.data; },

  SET_AGENT_STATS_DETAIL_LOADING: (st, payload) => { st.isStatsDetailLoading = payload.bool; },

  SET_TOTAL_AGENTS_COUNT: (st, payload) => { st.totalAgentsCount = payload.totalAgentsCount; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

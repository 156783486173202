// import { Message } from 'element-ui';
import http from '../../utils/http';

// Initial state
const state = {
  appList: [],

  user: {},
  timeZoneName: null,
  timeZoneOffset: 0,
  timeZone: '(UTC-00)',
  isTimeZoneAllowed: false,
};

// Getters
const getters = {
  getAppList: st => st.appList,
  getUser: st => st.user,
  getTz: (st) => {
    const {
      timeZone,
      timeZoneName,
      timeZoneOffset,
    } = st;

    return {
      timeZone,
      timeZoneName,
      timeZoneOffset,
    };
  },
};

// Actions
const actions = {
  // Fetch tenant account
  fetchAppList: async ({ commit }) => {
    try {
      const { data } = await http.get('/video/tenants/applications');
      const d = data.map(item => ({ name: `app-${item.AppId}`, value: item.AppId }));
      commit({ type: 'SET_APP_LIST', data: d });

      return data;
    } catch (err) {
      // Message.error({
      //   message: err.message,
      //   showClose: true,
      // });
      return Promise.reject(err);
    }
  },

  getUser({ commit }) {
    try {
      const cpv3User = localStorage.getItem('CPV3_User') || {};
      const userData = JSON.parse(cpv3User);

      const token = localStorage.getItem('WWW-Authenticate');
      const user = { ...userData, Token: token };

      commit('SET_USER', { user });
    } catch (err) {
      // Message.error({
      //   message: err.message,
      //   showClose: true,
      // });

    }
  },
};

// Mutations
const mutations = {
  SET_APP_LIST: (st, payload) => {
    st.appList = payload.data;
  },

  SET_USER(st, payload) {
    // eslint-disable-next-line no-param-reassign
    st.user = payload.user;

    const {
      TimeZoneName,
      TimeZoneGMTOffset,
    } = payload.user;

    st.isTimeZoneAllowed = true;

    // User timezone settings
    st.timeZoneName = TimeZoneName || 'UTC / Greenwich Mean Time'; // eslint-disable-next-line
    st.timeZoneOffset = TimeZoneGMTOffset ? (TimeZoneGMTOffset / 60) : 0; // offset should be in minutes
    let timeZone = '(UTC-00)';
    if (TimeZoneGMTOffset) {
      const offset = new Date(TimeZoneGMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
      let prefix = '-';
      if (TimeZoneGMTOffset > 0) {
        prefix = '+';
      }
      timeZone = `(UTC${prefix}${offset})`;
    }
    st.timeZone = timeZone;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div
    :ref="`card${agentId}`"
    role="button"
    :class="classObject"
    :tabindex="disabled ? -1 : index"
    @focus.native="updateFilterQuery({ selected: agentId })"
    @click="viewDetail(agentId)">
    <i class="el-icon-more absolute pin-t pin-r text-normal p-2" />

    <!-- User Detail -->
    <div class="flex items-center">
      <div class="relative">
        <AgentAvatar
          v-if="topRank"
          class=""
          :url="avatar"
          :status="isOnline"
          size="base" />
        <AgentAvatar
          v-else
          class=""
          :url="avatar"
          :status="isOnline"
          size="md" />
        <div
          v-if="index == 1"
          class="rank-badge rank-badge--first absolute pin-b pin-r w-6 h-6 xs:m-0 sm:m-0 xl:m-1 rounded-full flex items-center justify-center">
          <span>{{ index }}st</span>
        </div>
        <div
          v-else-if="index == 2"
          class="rank-badge rank-badge--second absolute pin-b pin-r w-6 h-6 xs:m-0 sm:m-0 xl:m-1 rounded-full flex items-center justify-center">
          <span>{{ index }}nd</span>
        </div>
        <div
          v-else-if="index == 3"
          class="rank-badge rank-badge--third absolute pin-b pin-r w-6 h-6 xs:m-0 sm:m-0 xl:m-1 rounded-full flex items-center justify-center">
          <span>{{ index }}rd</span>
        </div>
        <div
          v-else
          class="rank-badge absolute pin-b pin-r w-6 h-6 xs:m-0 sm:m-0 xl:m-1 rounded-full flex items-center justify-center">
          <span>{{ index }}th</span>
        </div>
      </div>
      <div class="ml-3">
        <h3
          v-if="topRank"
          class="text-lg font-normal text-black name">
          {{ name || login }}
        </h3>
        <h3
          v-else
          class="text-base font-normal text-black name">
          {{ name || login }}
        </h3>
        <span v-if="topRank">{{ $tc('column_labels.agent', 1) }}</span>
        <span
          v-else
          class="text-sm">{{ $tc('column_labels.agent', 1) }}</span>
      </div>
    </div>

    <!-- Total Calls -->
    <div
      v-if="topRank || order === 1"
      class="flex justify-between items-center mt-4 pt-4 border-t border-grey-light ">
      <span class="text-sm mr-5">
        {{ $t('column_labels.calls_made') }}
      </span>
      <div class="flex justify-between items-center w-1/3 flex-no-shrink">
        <div class="text-lg text-black w-full text-right">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('video.performance_modal[4]')"
            placement="top">
            <span class="text-green">
              {{ connectedCalls }}
            </span>
          </el-tooltip>
          -
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('video.performance_modal[8]')"
            placement="top">
            <span class="text-blue">
              {{ totalCalls - connectedCalls }}
            </span>
          </el-tooltip>
        </div>
      </div>
    </div>

    <!-- Call Duration -->
    <div
      v-if="topRank || order === 2"
      class="flex justify-between items-center border-t border-grey-light mt-4 pt-4">
      <span class="w-2/3 text-sm mr-5">
        {{ $t('video.dashboard.index[6]') }}
      </span>
      <div class="flex justify-between items-center w-1/3 flex-no-shrink">
        <div class="text-lg text-black w-full text-right">
          {{ avgDuration && decimalToDuration((avgDuration || 0) / (connectedCalls || 0)) }}
        </div>
      </div>
    </div>

    <!-- User Score -->
    <div
      v-if="topRank || order === 3"
      class="flex justify-between items-center border-t border-grey-light mt-4 pt-4">
      <span class="w-2/3 text-sm mr-5">
        {{ $t('column_labels.call_rating') }}
      </span>
      <div
        v-if="avgRating > 0"
        class="flex justify-between items-center w-1/3 flex-no-shrink">
        <div class="text-lg text-black w-full text-right">
          {{ avgRating }}
          <span class="text-sm">
            / 5
          </span>
        </div>
      </div>
      <div
        v-else
        class="flex justify-between items-center w-1/3 flex-no-shrink">
        <div class="text-lg text-grey w-full text-right">
          {{ $t('app_labels.none') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgentAvatar from '@/components/AgentAvatar';
import timeFormatter from '@/mixins/timeFormatter';

export default {
  name: 'PerformanceCard',

  components: { AgentAvatar },

  mixins: [timeFormatter],

  props: {
    index: {
      type: Number,
      default: 0,
    },
    isOnline: {
      type: Boolean,
      default: false,
    },
    topRank: {
      type: Boolean,
      default: false,
    },
    agentId: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'Agent',
    },
    login: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    totalCalls: {
      type: Number,
      default: 0,
    },
    connectedCalls: {
      type: Number,
      default: 0,
    },
    avgDuration: {
      type: Number,
      default: 0,
    },
    avgRating: {
      type: Number,
      default: 0,
    },
    order: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classObject() {
      const isSelect = this.$route.query.selected === this.agentId;
      return {
        'relative': true,
        'h-full': true,
        'outline-none': true,
        'text-grey': true,
        'cursor-pointer': !this.disabled,
        'hover:bg-grey-lightest': !this.disabled,
        'bg-blue-lightest': isSelect,
        'border-blue-lightest': isSelect,
        'opacity-75': this.disabled,
        'text-blue': isSelect,
        'bg-white': true,
        'p-5': true,
        'rounded': true,
        'shadow': true,
      };
    },
  },

  mounted() {
    // focus selected card
    const i = this.agentId;
    const { selected } = this.$route.query;
    if (selected === i) {
      this.$refs[`card${i}`].focus();
    }
  },

  methods: {

    // Update query
    updateFilterQuery(query) {
      if (!this.disabled) {
        this.$router.push({
          query: {
            ...this.$route.query,
            ...query,
          },
        });
      }
    },

    // Open Agent Performance Modal
    viewDetail(id) {
      if (!this.disabled) {
        this.updateFilterQuery({ selected: id, modal: 'AgentPerformance' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.trend {
  font-size: 8px;
}

.name {
  word-break: break-all;
}

.rank-badge {
  font-size: 10px;
    color: #96788A;
    background: linear-gradient(135deg, rgba(252,248,248,1) 0%, rgba(235,230,230,1) 43%, rgba(235,230,230,1) 59%, rgba(252,248,248,1) 99%, rgba(252,248,248,1) 100%);

  &--first {
    color: #ab793f;
    background: linear-gradient(135deg, rgba(245,222,179,1) 0%, rgba(245,194,132,1) 100%);
  }

  &--second {
    color: #6C6C6C;
    background: linear-gradient(135deg, rgba(199,199,199,1) 0%, rgba(227,223,223,1) 49%, rgba(227,223,223,1) 100%);
  }

  &--third {
    color: #76634E;
    background: linear-gradient(to bottom, rgba(227,202,174,1) 0%, rgba(204,182,159,1) 43%, rgba(204,182,159,1) 59%, rgba(227,202,174,1) 99%, rgba(227,202,174,1) 100%);
  }
}
</style>

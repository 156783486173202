<template>
  <el-dialog
    title="Update app"
    :visible.sync="isVisible"
    width="30%"
    :show-close="false"
    :before-close="handleClose"
    @open="initValues">
    <span class="text-xs block mb-2">
      {{ $t('video.update_app_modal[0]') }} {{ agent.Name || agent.Login }}
    </span>
    <el-select
      v-model="appValue"
      size="small"
      class="w-full"
      filterable
      @change="updateAgentAppFilter">
      <el-option
        v-for="app in apps"
        :key="app.value"
        :label="`app-${app.name}`"
        :value="app.value" />
    </el-select>

    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        @click="$emit('close-modal')">
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        :disabled="agent.AppId_Default === appValue"
        type="primary"
        @click="handleClose">
        {{ $t('actions.confirm') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isVisible: {
      default: false,
      type: Boolean,
    },

    agent: {
      required: true,
      type: Object,
    },

    apps: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      appValue: 2,
    };
  },

  methods: {
    initValues() {
      const { AppId_Default: defaultAppId } = this.agent;
      this.appValue = defaultAppId || '';
    },

    handleClose(done) {
      if (this.agent.AppId_Default === this.appValue) {
        done();
        return;
      }

      this.$confirm(this.$t('video.update_app_modal[1]'))
        .then((_) => { // eslint-disable-line
          this.$emit('update-app', this.appValue);
          done();
        })
        .catch((_) => {}); // eslint-disable-line
    },
  },
};
</script>

import status from '@/json/status.json';

export default {
  methods: {
    // Get Region Name
    getStatus(val) {
      const mapStatus = status.filter(s => s.value === val);
      // return (mapStatus.length <= 0) ? '-' : mapStatus[0].name;

      return (mapStatus.length <= 0) ? '-' : this.$t(`video.call_status.${mapStatus[0].name}`);
    },
  },
};

<template>
  <div class="flex items-center">
    <ul class="list-reset flex flex-row-reverse justify-end">
      <li
        v-for="(agent, i) in agentPreview"
        :key="i">
        <el-tooltip
          :content="agent.Name || $t('video.dashboard.agents[0]')"
          open-delay="500"
          placement="top">
          <AgentAvatar
            :url="agent.ProfileUrl"
            :disable-status="true"
            size="sm"
            class="-ml-2 rounded-full avatar" />
        </el-tooltip>
      </li>
    </ul>
    <router-link
      v-if="agentMore.length"
      to="/agents"
      class="text-sm text-blue no-underline ml-2">
      +{{ agentMore.length }} {{ $t('video.dashboard.agents[1]') }}
    </router-link>
  </div>
</template>

<script>
import AgentAvatar from '@/components/AgentAvatar.vue';

export default {
  name: 'Agents',

  components: { AgentAvatar },

  props: {
    agents: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      agentPreview: [],
      agentMore: [],
    };
  },

  created() {
    const limit = 10;
    const agentList = this.agents.reverse();
    this.agentPreview = agentList.slice(0, limit);
    this.agentMore = agentList.slice(limit + 1);
  },
};
</script>

<style scoped lang="scss">
.avatar  {
  border: solid 1px white;
  position: relative;
  box-sizing: content-box;

  &:hover {
    border: solid 1px transparent;
    transform: translateY(-2px);
    z-index: 999;
    transition: transform  var(--ease);
  }
}
</style>

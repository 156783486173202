export default {
  methods: {
    // Invite agent propt message box
    inviteAgent() {
      const self = this;
      this.$prompt(self.$t('video.agent_mixin[0]'), self.$t('video.agent_mixin[1]'), {
        confirmButtonText: self.$t('video.agent_mixin[2]'),
        cancelButtonText: self.$t('actions.cancel'),
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: self.$t('validations.valid', { value: self.$t('fields.email') }),
      }).then(({ value }) => {
        this.$store.dispatch('inviteAgent', { email: value })
          .then(() => {
            this.$message.success(`${value} ${self.$t('video.agents.index[6]')}`);
          }).catch((err) => {
            self.$showError(this, err);
          });
      }).catch(() => {});
    },
  },
};

<template>
  <div :class="[origin === 'agent' ? 'mb-2 flex w-full text-right' : 'mb-2 flex w-full text-left']">
    <div class="w-full">
      <span
        v-if="!isImage()"
        :class="[origin === 'agent' ? 'bg-grey-lighter text-xs text-black shadow rounded-lg rounded-br-none overflow-hidden inline-block' : 'bg-white border border-grey-light text-xs text-black shadow rounded-lg rounded-tl-none overflow-hidden inline-block']">
        <span
          v-if="!isUrl(text)"
          class="break-words p-3 inline-block">
          {{ text }}
        </span>
        <a
          v-else
          :href="urlify(text)"
          class="break-words p-3 inline-block no-underline text-blue flex items-center hover:text-blue-darker"
          target="_blank">
          {{ text }}
          <i class="ml-1 material-icons text-xs">
            launch
          </i>
        </a>
      </span>
      <span
        v-else
        class="w-full">
        <img
          :src="text"
          alt=""
          :class="[origin === 'agent' ? 'image bg-grey-lighter text-xs text-black shadow rounded-lg rounded-br-none overflow-hidden inline-block' : 'image bg-white border border-grey-light text-xs text-black shadow rounded-lg rounded-tl-none overflow-hidden inline-block']">
        <span class="ml-3 inline-block">
          <a
            class="material-icons cursor-pointer no-underline text-black hover:text-blue inline-block"
            :href="text">
            vertical_align_bottom
          </a>
        </span>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    origin: {
      type: String,
      default: 'guest',
    },
    timestamp: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
  },

  methods: {
    urlify(url) {
      return url.replace(/^(?:http(s)?:\/\/)?(www.)?/, '//');
    },

    isUrl(url) {
      return url.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w-._~:/?#[\]@!$&'()*+,;=.]+$/g);
    },

    isImage() {
      if (this.type === 'image') {
        return true;
      }

      return !!this.text.match(/https*:\/\/(.*.)\/snapshots\/(.*.(?:png|jpg|jpeg|gif|png|svg))/);
    },
  },
};
</script>


<style lang="scss" scoped>
.image {
  max-width: 50%;
}
</style>

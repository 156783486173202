// import { Message } from 'element-ui';
import http from '../../utils/http';

// Initial state
const state = {
  currentConference: {},
  showConferenceModal: false,
  isCallLogLoading: false,
  isDetailLoading: false,
  isCallStatsLoading: false,
  conferenceLogs: [],
  conferenceLogsAll: [],
  cursorOption: {},
  conferenceStats: {},
  incomingCallStats: {},
  isIncomingCallLoading: false,
};

// Getters
const getters = {
  getCallLogLoading: st => st.isCallLogLoading,
  getDetailLoading: st => st.isDetailLoading,
  getConferenceLogs: st => st.conferenceLogs,
  getConferenceLogsAll: st => st.conferenceLogsAll,
  getShowConferenceModal: st => st.showConferenceModal,
  getConferenceDetail: st => st.currentConference,
  getCallLogTotal: st => st.conferenceLogs.length,
  getCursorOption: st => st.cursorOption,
  getStatsLoading: st => st.isCallStatsLoading,
  getCallStats: st => st.conferenceStats,
  getIncomingCallStats: st => st.incomingCallStats,
  getIncomingCallLoading: st => st.isIncomingCallLoading,
};

// Actions
const actions = {
  // Fetch call stats
  fetchCallStats: async ({ commit }, payload) => {
    // Set call stats loading to true
    commit({ type: 'SET_CONFERENCE_STATS_LOADING', bool: true });

    try {
      const { data } = await http.get('/calls/stats', payload);

      commit({ type: 'SET_CONFERENCE_STATS_LOADING', bool: false });
      commit({ type: 'SET_CONFERENCE_STATS', stats: data });
      return data;
    } catch (err) {
      // const message = 'Failed to fetch dashboard data';
      // Message.error({
      //   message,
      //   showClose: true,
      // });
      commit({ type: 'SET_CONFERENCE_STATS_LOADING', bool: false });
      return Promise.reject(err);
    }
  },

  // Fetch call logs
  fetchConferenceLogs: async ({ commit }, payload) => {
    // Set loading to true
    commit({ type: 'SET_CONFERENCE_LOG_LOADING', bool: true });

    try {
      const { data } = await http.get('/calls', payload);
      const { list, prevCursor, nextCursor } = data;

      commit({ type: 'SET_CURSOR_OPTION', cursors: { prevCursor, nextCursor } });
      commit({ type: 'SET_CONFERENCE_LOG', logs: list });
      commit({ type: 'SET_CONFERENCE_LOG_LOADING', bool: false });

      return data;
    } catch (err) {
      // Set loading to false
      commit({ type: 'SET_CONFERENCE_LOG_LOADING', bool: false });
      return Promise.reject(err);
    }
  },

  // Fetch call logs csv
  fetchConferenceLogsCsv: async ({ commit }, payload) => {
    const p = {
      ...payload,
    };

    return new Promise((resolve, reject) => {
      http.get('/calls', p)
        .then((response) => {
          const { data } = response;
          commit({ type: 'SET_CONFERENCE_CSV', csv: data.list });
          resolve(data.list);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Fetch call details
  fetchConferenceDetails: async ({ commit }, conference) => {
    commit({ type: 'SET_OPEN_CONFERENCE_DETAIL_MODAL', bool: true });

    const {
      CallId: callId,
      UserA_Agent: userAgent,
      CountryISO2alpha: countryISO,
      UserB_MSISDN: phone,
      Login: email,
      Name: name,
      Reference: refId,
      CreatedAt: createDate,
      Duration: duration,
    } = conference;

    const defaults = {
      countryISO: countryISO ? countryISO.toLowerCase() : '', phone, email, name, refId, createDate, duration,
    };
    commit({ type: 'SET_CONFERENCE_DETAIL', data: defaults });

    try {
      commit({ type: 'SET_CONFERENCE_DETAIL_LOADING', bool: true });

      const { data } = await http.get(`/calls/${callId}/?agent=${userAgent}`);
      const confDetail = { ...defaults, ...data };

      commit({ type: 'SET_CONFERENCE_DETAIL', data: confDetail });
      commit({ type: 'SET_CONFERENCE_DETAIL_LOADING', bool: false });
      return data;
    } catch (err) {
      commit({ type: 'SET_CONFERENCE_DETAIL_LOADING', bool: false });
      return Promise.reject(err);
    }
  },

  // Fetch Incoming call stats
  fetchIncomingCallStats: async ({ commit }, payload) => {
    const {
      week,
      utcOffset,
      timeZone,
    } = payload;

    commit({ type: 'SET_INCOMING_CALL_LOADING', bool: true });

    try {
      const { data } = await http.get(`/inbound-calls/summary?weeks=${week}&utcOffset=${utcOffset}&timeZone=${timeZone}`);
      let callStatsData = [];

      if (Object.keys(data || {}).length) {
        callStatsData = data;
      }

      commit({ type: 'SET_INCOMING_CALL_STATS', stats: callStatsData });

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_INCOMING_CALL_LOADING', bool: false });
    }
  },

};

// Mutations
const mutations = {
  SET_CURSOR_OPTION: (st, payload) => {
    st.cursorOption = payload.cursors;
  },
  SET_OPEN_CONFERENCE_DETAIL_MODAL: (st, payload) => { st.showConferenceModal = payload.bool; },

  SET_CONFERENCE_DETAIL: (st, payload) => {
    st.currentConference = payload.data;
  },

  SET_CONFERENCE_LOG: (st, payload) => {
    st.conferenceLogs = payload.logs;
  },

  SET_CONFERENCE_CSV: (st, payload) => {
    st.conferenceLogsAll = payload.csv;
  },

  SET_CONFERENCE_LOG_LOADING: (st, payload) => {
    st.isCallLogLoading = payload.bool;
  },

  SET_CONFERENCE_DETAIL_LOADING: (st, payload) => {
    st.isDetailLoading = payload.bool;
  },

  SET_CONFERENCE_STATS_LOADING: (st, payload) => {
    st.isCallStatsLoading = payload.bool;
  },

  SET_CONFERENCE_STATS: (st, payload) => {
    st.conferenceStats = payload.stats;
  },

  SET_INCOMING_CALL_STATS: (st, payload) => {
    st.incomingCallStats = payload.stats;
  },

  SET_INCOMING_CALL_LOADING: (st, payload) => {
    st.isIncomingCallLoading = payload.bool;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="relative pb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('sidebar_menu_children.Performance') }}
    </h1>
    <div class="px-3 py-4 rounded bg-grey-darkest shadow flex justify-between items-center mb-10">
      <h1 class="flex text-base font-normal items-center text-white">
        {{ $t('video.performance.index[0]') }}
      </h1>
      <Filters
        :loading="isAgentStatsLoading"
        :week="weeksValue"
        :week-options="weekFilter"
        :agent="agentFilterSelect"
        :agent-options="agents"
        :order="orderValue"
        :order-options="orderFilter"
        @update-filter="updateFilterQuery" />
    </div>

    <!-- Content -->
    <div
      v-loading="isAgentStatsLoading"
      class="mt-5">
      <div
        v-if="!agentsStats.length"
        class="p-32 flex items-center justify-center flex-col text-grey-dark">
        <div class="text-center p-10 text-xs text-black leading-loose">
          <h1 class="text-black text-base mb-5 font-semibold">
            {{ $t('video.performance.index[1]') }}
          </h1>
          <span class="text-sm text-grey block mb-5">
            {{ $t('video.performance.index[2]') }}
          </span>
        </div>
      </div>
      <el-row
        v-else
        :gutter="10"
        type="flex"
        class="mb-4 flex-wrap">
        <!-- Top 3 Agents -->
        <el-col
          v-for="(agt, i) in agentsStats.slice(0, 3)"
          :key="i+1"
          :xs="24"
          :sm="24"
          :lg="8"
          class="mb-3">
          <PerformanceCard
            v-loading="isAgentStatsLoading"
            :index="i+1"
            :is-online="agt.IsLoggedIn"
            :top-rank="true"
            :agent-id="agt.agent"
            :name="agt.Name"
            :login="agt.Login"
            :avatar="agt.ProfileUrl"
            :total-calls="agt.TotalCalls || 0"
            :connected-calls="agt.ConnectedCalls || 0"
            :avg-duration="agt.DurationInMin || 0"
            :avg-rating="agt.AvgRating || 0"
            :order="parseInt(order) || 1" />
        </el-col>
        <!-- Rest of the Agents -->
        <el-col
          v-for="(agt, i) in agentsStats.slice(3)"
          :key="i+4"
          :xs="12"
          :sm="12"
          :lg="6"
          class="mb-3">
          <PerformanceCard
            v-loading="isAgentStatsLoading"
            :index="i+4"
            :is-online="agt.IsLoggedIn"
            :agent-id="agt.agent"
            :name="agt.Name"
            :login="agt.Login"
            :avatar="agt.ProfileUrl"
            :total-calls="agt.TotalCalls || 0"
            :connected-calls="agt.ConnectedCalls || 0"
            :avg-duration="agt.DurationInMin || 0"
            :avg-rating="agt.AvgRating || 0"
            :order="parseInt(order) || 1" />
        </el-col>
      </el-row>
    </div>

    <!-- Agent Performance Modal -->
    <PerformanceModal
      :weeks="weeks"
      :tz="tz" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PerformanceCard from '@/components/Agents/PerformanceCard.vue';
import PerformanceModal from '@/components/Agents/PerformanceModal.vue';
import Filters from './partials/Filters.vue';

export default {
  name: 'Performance',

  components: {
    PerformanceCard,
    PerformanceModal,
    Filters,
  },

  props: {
    weeks: {
      type: String,
      required: false,
      default: '',
    },
    agent: {
      type: [String, Number],
      required: false,
      default: '',
    },
    order: {
      type: [String, Number],
      required: false,
      default: '',
    },
    modal: {
      type: String,
      required: false,
      default: '',
    },
    selected: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    const self = this;

    return {
      weekFilter: [{
        value: 1,
        label: self.$t('date_labels.this_week'),
      }, {
        value: 2,
        label: self.$t('date_labels.past_2_weeks'),
      }, {
        value: 3,
        label: self.$t('date_labels.past_3_weeks'),
      }, {
        value: 4,
        label: self.$t('date_labels.past_4_weeks'),
      }],

      weeksValue: '',

      orderFilter: [{
        value: 1,
        label: self.$t('column_labels.total_calls'),
      }, {
        value: 2,
        label: self.$t('column_labels.avg_call_duration'),
      }, {
        value: 3,
        label: self.$t('column_labels.avg_call_rating'),
      }],

      orderValue: '',

      agentFilterSelect: '',
    };
  },

  computed: mapGetters({
    agents: 'getAgents',
    agentsStats: 'getAgentsStats',
    isAgentStatsLoading: 'getAgentStatsLoading',
    tz: 'getTz',
  }),

  watch: {
    '$route': function fetch(to, from) {
      const { selected: prevSelected, modal: prevModal } = to.query;
      const { selected: nextSelected, modal: nextModal } = from.query;

      // prevent fetching if 'selected' params is changed
      if (prevSelected === nextSelected && prevModal === nextModal) {
        this.fetchData();
      }
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    // Update query
    updateFilterQuery(query) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    },

    // Fetch Data
    async fetchData() {
      const { weeks, agent, order } = this;

      this.weeksValue = (parseInt(weeks, 10) > 4 || parseInt(weeks, 10) < 1 || !weeks) ? 1 : parseInt(weeks, 10);

      this.orderValue = parseInt(order, 10) || this.orderFilter[0].value;
      this.agentFilterSelect = agent || '';

      try {
        await this.$store.dispatch('fetchAgents', {
          fields: ['UserId', 'Name', 'Login'],
        });

        await this.$store.dispatch('fetchAgentsStats', {
          weeks: this.weeksValue,
          agent: this.agentFilterSelect,
          order: this.orderValue,
          utcOffset: this.tz.timeZoneOffset,
          timeZone: this.tz.timeZone,
        });
      } catch (err) {
        this.$showError(this, err);
      }

      // return false;
    },
  },

};
</script>

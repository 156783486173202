import Dashboard from './views/Dashboard/index.vue';
import CallLogs from './views/CallLogs/index.vue';
import Agents from './views/Agents/index.vue';
import Performance from './views/Performance/index.vue';
import Register from './views/Register.vue';
import IncomingCall from './views/IncomingCall/index.vue';
import Layout from './views/Layout.vue';

export default [
  {
    path: '/',
    name: 'dashboard',
    component: Layout,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
        props: route => ({ weeks: route.query.weeks }),
      },
      {
        path: 'call-logs',
        name: 'call-logs',
        component: CallLogs,
        props: route => (
          {
            from: route.query.from,
            to: route.query.to,
            agent: route.query.agent,
            q: route.query.q,
            status: route.query.status,
          }),
      },
      {
        path: 'agents',
        name: 'agents',
        component: Agents,
        props: route => ({
          q: route.query.q,
          status: route.query.status,
          app: route.query.app,
          selected: route.query.selected,
          modal: route.query.modal,
        }),
      },
      {
        path: 'performance',
        name: 'performance',
        component: Performance,
        props: route => ({
          weeks: route.query.weeks,
          agent: route.query.agent,
          order: route.query.order,
          modal: route.query.modal,
          selected: route.query.selected,
        }),
      },
      {
        path: 'incoming-calls',
        name: 'incoming-calls',
        component: IncomingCall,
        props: route => ({
          weeks: route.query.weeks,
        }),
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
];

<template>
  <el-container
    class="h-full flex">
    <div class="h-full w-full flex">
      <!-- chat sidebar nav -->
      <el-main
        class="p-0 bg-white h-full"
        style="padding: 0;">
        <section class="h-full main__section">
          <RouterView />
        </section>
      </el-main>
    </div>
    <ConferenceDetailModal />
    <ProfileModal v-if="agentList.length" />
  </el-container>
</template>

<script>
import ConferenceDetailModal from '@/components/Analytics/ConferenceDetailModal.vue';
import ProfileModal from '@/components/Agents/ProfileModal.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ConferenceDetailModal,
    ProfileModal,
  },
  computed: {
    ...mapGetters({
      agentList: 'getAgents',
    }),
  },
};
</script>

export default {
  methods: {
    // Get Region Name
    highlight(s, match) {
      if (!s) { return s; }

      return s.replace(
        new RegExp(this.escapeRegExp(match), 'gi'),
        m => `<span class="text-blue">${m}</span>`,
      );
    },

    // Escape string
    escapeRegExp(s) {
      return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },

  },
};

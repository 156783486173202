<template>
  <div class="relative pb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('sidebar_menu_children.Incoming calls') }}
    </h1>
    <div class="px-3 py-4 rounded bg-grey-darkest shadow flex justify-between items-center mb-10">
      <h1 class="flex text-base font-normal items-center text-white">
        {{ $t('video.incoming_call.index[0]') }}
      </h1>
      <Filters
        :loading="loading"
        :week="weeksValue"
        :week-options="weekFilter"
        :app="appValue"
        :app-options="appFilter"
        @update-filter="updateFilterQuery"
        @update-app="v => { appValue = v }" />
    </div>

    <div
      v-loading="loading"
      class="w-full">
      <el-row
        :gutter="10"
        type="flex"
        class="mb-4 flex-wrap">
        <el-col
          :span="12"
          class="mb-3">
          <div class="mb-8 relative h-full text-black flex justify-center items-center flex-col xl:flex-row">
            <div class="w-auto lg:w-full p-2 text-center xl:text-left">
              <p class="text-sm text-grey-dark">
                {{ $t('video.incoming_call.index[1]') }}
              </p>
              <span class="pt-4 text-black text-3xl block">{{ queueCount }}</span>
            </div>
            <div class="w-auto lg:w-full p-2 text-center xl:text-left">
              <p class="text-sm text-grey-dark">
                {{ $t('video.incoming_call.index[2]') }}
              </p>
              <span class="pt-4 text-black text-3xl block">{{ connectedAgentsCount }}</span>
            </div>
          </div>
        </el-col>

        <el-col
          :span="12"
          class="mb-3">
          <div class="mb-8 relative h-full text-black flex justify-center items-center flex-col xl:flex-row">
            <div class="w-auto lg:w-full p-2 text-center xl:text-left">
              <p class="text-sm text-grey-dark">
                {{ $t('video.incoming_call.index[3]') }}
              </p>
              <span class="pt-4 text-black text-3xl block">{{ getTotalAvgUsers() }}</span>
            </div>
            <div class="w-auto lg:w-full p-2 text-center xl:text-left">
              <p class="text-sm text-grey-dark">
                {{ $t('video.incoming_call.index[4]') }}
              </p>
              <span class="pt-4 text-black text-3xl block">
                {{ getTotalWaitTime() }}
                <span class="text-sm text-grey-dark pl-1">
                  {{ getTotalWaitTime() !== 1 ? $tc('time_labels.second', 2) : $tc('time_labels.second', 1) }}
                </span>
              </span>
            </div>
          </div>
        </el-col>

        <el-col
          :xs="24"
          :lg="12"
          class="mb-3">
          <div class="p-8 relative h-full text-black bg-white rounded shadow">
            <div class="flex justify-between">
              <h2 class="text-sm text-black">
                {{ $t('video.incoming_call.index[5]') }}
              </h2>
            </div>
            <div class="mt-10">
              <div class="h-48 relative">
                <div class="w-full h-full">
                  <BarChart
                    :chart-data="getData('avg_waiting_time', $t('video.incoming_call.index[5]'))"
                    :options="avgWaitTimeBarOption"
                    class="w-full h-full" />
                  <div class="flex justify-between text-xs mt-2">
                    <span>{{ startDateLabel }}</span>
                    <span>{{ endDateLabel }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col
          :xs="24"
          :lg="12"
          class="mb-3">
          <div class="p-8 relative h-full text-black bg-white rounded shadow">
            <div class="flex justify-between">
              <h2 class="text-sm text-black">
                {{ $t('video.incoming_call.index[6]') }}
              </h2>
            </div>
            <div class="mt-10">
              <div class="h-48 relative">
                <div class="w-full h-full">
                  <BarChart
                    :chart-data="getData('count', $t('video.incoming_call.index[7]'))"
                    :options="barChartOption"
                    class="w-full h-full" />
                  <div class="flex justify-between text-xs mt-2">
                    <span>{{ startDateLabel }}</span>
                    <span>{{ endDateLabel }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col
          :span="24"
          class="mb-3">
          <div class="p-8 relative h-full text-black bg-white rounded shadow">
            <h2 class="text-sm text-black">
              {{ $t('video.incoming_call.index[8]') }}
            </h2>
            <div class="mt-10 flex flex-col-reverse xl:flex-row flex-wrap">
              <div class="h-48 flex-grow w-full xl:w-5/6 relative">
                <div class="w-full h-full">
                  <LineChart
                    :chart-data="callsData()"
                    :options="lineChartOption"
                    class="w-full h-full" />
                  <div class="flex justify-between text-xs mt-2">
                    <span>{{ startDateLabel }}</span>
                    <span>{{ endDateLabel }}</span>
                  </div>
                </div>
              </div>
              <div class="flex px-0 xl:px-10 pb-8 xl:pb-0 w-auto xl:w-1/6">
                <div class="flex flex-col justify-center text-center xl:text-left mx-auto xl:mx-0">
                  <p class="text-sm text-grey-dark flex items-center relative">
                    <span class="legend" />
                    {{ $t('video.incoming_call.index[11]') }}
                  </p>
                  <span class="pt-4 text-black text-3xl block">{{ getTotalIncomingCall() }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import Moment from 'moment';

import BarChart from '@/components/BarChart';
import LineChart from '@/components/LineChart';
import timeFormatter from '@/mixins/timeFormatter';
import Filters from './partials/Filters.vue';


export default {
  name: 'IncomingCalls',

  components: {
    BarChart,
    LineChart,
    Filters,
  },

  mixins: [timeFormatter],

  props: {
    weeks: {
      type: String,
      required: false,
      default: '',
    },
    app: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    const self = this;
    return {
      startDateLabel: '',
      endDateLabel: '',
      lineChartOption: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [{
            display: false,
          }],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
      },

      barChartOption: {
        legend: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            display: false,
          }],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },

      avgWaitTimeBarOption: {
        legend: false,
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            // label: item => `Avg. wait time: ${item.yLabel} second/s`,
            label: item => self.$t('video.incoming_call.index[9]', { label: item.yLabel }),
          },
        },
        scales: {
          xAxes: [{
            display: false,
          }],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },

      waitSpan: 'day',

      queueSpan: 'day',

      weekFilter: [
        {
          value: 1,
          label: self.$t('date_labels.last_7_days'),
        },
        {
          value: 2,
          label: self.$t('date_labels.past_2_weeks'),
        },
        {
          value: 3,
          label: self.$t('date_labels.past_3_weeks'),
        },
        {
          value: 4,
          label: self.$t('date_labels.past_4_weeks'),
        },
      ],

      weeksValue: '',

      appValue: -1,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'getIncomingCallLoading',
      incomingCallStats: 'getIncomingCallStats',
      tz: 'getTz',
    }),

    // App filter option
    appFilter() {
      let data = [];
      if (this.incomingCallStats.logged_in_agents !== undefined && this.incomingCallStats.logged_in_agents.length) {
        data = this.incomingCallStats.logged_in_agents.map(item => ({
          value: item.app_id,
          label: `app-${item.app_id}`,
        }));
      }

      const self = this;

      return [
        {
          value: -1,
          label: self.$t('video.incoming_call.index[10]'),
        },
        ...data,
      ];
    },

    // Current no. of users in queue
    queueCount() {
      if (this.incomingCallStats.queue_users_count !== undefined && this.incomingCallStats.queue_users_count.length) {
        return this.incomingCallStats.queue_users_count[0].count;
      }
      return 0;
    },

    // Current no. of connected agents
    connectedAgentsCount() {
      let data = 0;
      if (this.incomingCallStats.logged_in_agents !== undefined && this.incomingCallStats.logged_in_agents.length) {
        if (this.appValue < 0) {
          // If sum of all app
          data = this.incomingCallStats.logged_in_agents.reduce((prev, curr) => prev + curr.count, 0);
        } else {
          // If specific app
          const filter = this.incomingCallStats.logged_in_agents.filter(i => i.count === this.appValue);
          data = filter.length ? filter[0].count : 0;
        }
      }
      return data;
    },

    // Incoming calls summary
    callsDataSummary() {
      const data = {
        duration: 0,
        trend: 0,
      };

      if (this.incomingCallStats.incoming_calls !== undefined) {
        const { count, duration } = this.incomingCallStats.incoming_calls.summary[0];

        data.count = count;
        data.duration = duration;
        data.trend = (duration / count).toFixed(2);

        // Add sign to trend
        if (data.trend >= 1) {
          data.trend = `+${data.trend}`;
        } else {
          data.trend = `-${data.trend}`;
        }
      }

      return data;
    },
  },

  watch: {
    '$route': function fetch() {
      this.fetchData();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions({
      fetchIncomingCallStats: 'fetchIncomingCallStats',
    }),

    getWeeksValue(weeks) {
      let endDate = '';
      let startDate = '';
      let timeType = '';

      switch (weeks) {
        case 1: {
          // this week
          endDate = Moment.utc().utcOffset(this.tz.timeZoneOffset);
          startDate = Moment.utc().utcOffset(this.tz.timeZoneOffset).subtract(7, 'day');
          timeType = 'days';
          break;
        }

        case 2: {
          // last week
          endDate = Moment.utc().utcOffset(this.tz.timeZoneOffset);
          startDate = Moment().utc().utcOffset(this.tz.timeZoneOffset).subtract(14, 'day');
          timeType = 'days';
          break;
        }

        case 3: {
          // past 2 weeks
          endDate = Moment.utc().utcOffset(this.tz.timeZoneOffset);
          startDate = Moment().utc().utcOffset(this.tz.timeZoneOffset).subtract(21, 'days');
          timeType = 'days';
          break;
        }

        case 4: {
          // this month
          endDate = Moment.utc().utcOffset(this.tz.timeZoneOffset);
          startDate = Moment.utc().utcOffset(this.tz.timeZoneOffset).subtract(28, 'day');
          timeType = 'days';
          break;
        }

        case 5: {
          // today
          endDate = Moment.utc().utcOffset(this.tz.timeZoneOffset).endOf('day');
          startDate = Moment.utc().utcOffset(this.tz.timeZoneOffset).startOf('day');
          timeType = 'hours';
          break;
        }

        default: {
          break;
        }
      }

      return {
        startDate,
        endDate,
        timeType,
      };
    },

    getTotalIncomingCall() {
      const data = this.callsData();
      if (!Object.keys(data).length) return 0;

      const total = data.datasets[0].data.reduce((sum, n) => sum + n, 0);

      return total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    },

    getTotalAvgUsers() {
      const data = this.getData('count', this.$t('video.incoming_call.index[7]'));
      if (!data || !Object.keys(data).length) return 0;

      const nonZeroValues = data.datasets[0].data.filter(d => d > 0);
      const total = nonZeroValues.reduce((sum, n) => sum + n, 0);
      const average = total / (nonZeroValues.length || 1);

      return average.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    },

    getTotalWaitTime() {
      const data = this.getData('avg_waiting_time', this.$t('video.incoming_call.index[5]'));
      if (!Object.keys(data).length) return 0;

      const nonZeroValues = data.datasets[0].data.filter(d => d > 0);
      const total = nonZeroValues.reduce((sum, n) => sum + n, 0);
      const average = total / (nonZeroValues.length || 1);

      return average.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    },

    // Incoming calls
    callsData() {
      const { incoming_calls: incomingCalls } = this.incomingCallStats;

      // return empty array if there is no data
      if (!Object.keys(incomingCalls || {}).length) return {};

      const { aggregated: appData } = incomingCalls;
      const {
        startDate,
        endDate,
        timeType,
      } = this.getWeeksValue(this.weeksValue);

      let dateRange = [];
      let dateIterator = new Date(startDate);
      while (dateIterator < new Date(endDate)) {
        dateRange = [...dateRange, Moment.utc(dateIterator).utcOffset(this.tz.timeZoneOffset).format()];
        dateIterator = Moment(dateIterator).add(1, timeType);
      }

      const missingDateAppData = dateRange.map(dt => ({
        count: 0,
        created: dt,
      }));

      const filledDatesAppData = [...appData, ...missingDateAppData];

      let mergeByDateTime = {};
      if (timeType !== 'hours') {
        // Date filter is not 'Today'
        mergeByDateTime = filledDatesAppData.reduce((acc, app) => {
          const datekey = Moment.utc(app.created).utcOffset(this.tz.timeZoneOffset).format('YYYY-MM-DD');
          const sum = (acc[datekey] || 0) + (app.count || 0);

          return acc = { // eslint-disable-line
            ...acc,
            [`${datekey}`]: sum,
          };
        }, {});
      } else {
        // Today
        mergeByDateTime = filledDatesAppData.reduce((acc, app) => {
          if (!Moment(startDate).isSame(app.created, 'day')) return acc;

          const datekey = Moment.utc(app.created).utcOffset(this.tz.timeZoneOffset).format('YYYY-MM-DD HH');
          const sum = (acc[datekey] || 0) + (app.count || 0);

          return acc = { // eslint-disable-line
            ...acc,
            [`${datekey}`]: sum,
          };
        }, {});
      }

      // sort date keys from ascending
      const sortedKeys = Object.keys(mergeByDateTime)
        .sort((a, b) => Moment(a).diff(Moment(b)));

      // remove keys that isnt within the date range
      const rangedDateKeys = sortedKeys
        .filter(dt => Moment(dt).isBetween(Moment(startDate), Moment(endDate), timeType, '[]'));

      const labelsList = rangedDateKeys.map(dt => Moment.utc(dt).utcOffset(this.tz.timeZoneOffset)
        .format(timeType === 'hours' ? `MMMM DD, YYYY HH:mm ${this.tz.timeZone}` : `MMMM DD, YYYY ${this.tz.timeZone}`));

      [this.startDateLabel] = labelsList;
      this.endDateLabel = labelsList[labelsList.length - 1];

      const self = this;

      return {
        labels: labelsList,
        datasets: [
          {
            label: self.$t('video.incoming_call.index[8]'),
            borderColor: '#1C3D5A',
            borderWidth: 2,
            backgroundColor: 'rgba(28, 61, 90, 0.5)',
            pointBorderWidth: 0,
            pointBackgroundColor: '#1C3D5A',
            radius: 2,
            data: rangedDateKeys.map(key => mergeByDateTime[key]),
            lineTension: 0,
            spanGaps: true,
          },
        ],
      };
    },

    // Avg. wait time
    getData(keyItem = 'avg_waiting_time', popupLabel) {
      const {
        aggregated,
        logged_in_agents: appList,
      } = this.incomingCallStats;

      const self = this;

      // return empty array if there is no data
      if (!appList || !appList.length) return [];

      let appData = [];
      if (this.appValue === -1) {
        // compute all app data
        let mergedData = [];
        appList.forEach((app) => {
          const days = aggregated[app.app_id] ? aggregated[app.app_id].days : [];
          mergedData = [
            ...mergedData,
            ...days,
          ];
        });
        appData = [...mergedData];
      } else {
        // compute specific app data
        appData = Object.keys(aggregated).includes(this.appValue) && aggregated[this.appValue] ? aggregated[this.appValue].days : [];
      }

      // get day within range
      let endDate = '';
      let startDate = '';
      let timeType = '';

      switch (this.weeksValue) {
        case 1: {
          // this week
          endDate = Moment().endOf('week');
          startDate = Moment().startOf('week');
          timeType = 'days';
          break;
        }

        case 2: {
          // last week
          endDate = Moment().subtract(1, 'week').endOf('week');
          startDate = Moment().subtract(1, 'week').startOf('week');
          timeType = 'days';
          break;
        }

        case 3: {
          // past 2 weeks
          endDate = Moment().endOf('week');
          startDate = Moment().startOf('week').subtract(1, 'week');
          timeType = 'days';
          break;
        }

        case 4: {
          // this month
          endDate = Moment().endOf('month');
          startDate = Moment().startOf('month');
          timeType = 'days';
          break;
        }

        case 5: {
          // today
          endDate = Moment().endOf('day');
          startDate = Moment().startOf('day');
          timeType = 'hours';
          break;
        }

        default: {
          break;
        }
      }

      let dateRange = [];
      let dateIterator = new Date(startDate);
      while (dateIterator < new Date(endDate)) {
        dateRange = [...dateRange, Moment(dateIterator).format()];
        dateIterator = Moment(dateIterator).add(1, timeType);
      }

      const missingDateAppData = dateRange.map(dt => ({
        count: 0,
        avg_waiting_item: 0,
        day: dt,
      }));

      const filledDatesAppData = [...appData, ...missingDateAppData];

      let mergeByDateTime = {};
      if (timeType !== 'hours') {
        // Date filter is not 'Today'
        mergeByDateTime = filledDatesAppData.reduce((acc, app) => {
          const datekey = Moment(app.day).format('YYYY-MM-DD');
          const sum = (acc[datekey] || 0) + (app[keyItem] || 0);

          return acc = { // eslint-disable-line
            ...acc,
            [`${datekey}`]: sum,
          };
        }, {});
      } else {
        // Today
        mergeByDateTime = filledDatesAppData.reduce((acc, app) => {
          if (!Moment(startDate).isSame(app.day, 'day')) return acc;

          const datekey = Moment(app.day).format('YYYY-MM-DD HH');
          const sum = (acc[datekey] || 0) + (app[keyItem] || 0);

          return acc = { // eslint-disable-line
            ...acc,
            [`${datekey}`]: sum,
          };
        }, {});
      }

      // sort date keys from ascending
      const sortedKeys = Object.keys(mergeByDateTime)
        .sort((a, b) => Moment(a).diff(Moment(b)));

      // remove keys that isnt within the date range
      const rangedDateKeys = sortedKeys
        .filter(dt => Moment(dt).isBetween(Moment(startDate), Moment(endDate), timeType, '[]'));

      return {
        labels: rangedDateKeys.map(dt => Moment(dt).format(timeType === 'hours' ? 'MMMM DD, YYYY HH:mm (UTC)' : 'MMMM DD, YYYY (UTC)')),
        datasets: [
          {
            label: popupLabel || self.$t('video.incoming_call.index[12]'),
            backgroundColor: '#8795A1',
            hoverBackgroundColor: '#1C3D5A',
            data: rangedDateKeys.map(key => mergeByDateTime[key]),
          },
        ],
      };
    },

    // Update query
    updateFilterQuery(query) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    },

    // Get Tab Button Class
    tabButtonClass(data, val) {
      return {
        'graph-button mx-3 button text-md': true,
        'graph-button--active': data === val,
      };
    },

    // Fetch Data
    fetchData() {
      const {
        weeks,
      } = this;

      this.weeksValue = (parseInt(weeks, 10) > 4 || parseInt(weeks, 10) < 1 || !weeks) ? 1 : parseInt(weeks, 10);

      this.fetchIncomingCallStats({
        week: this.weeksValue,
        utcOffset: this.tz.timeZoneOffset,
        timeZone: this.tz.timeZone,
      }).catch((err) => {
        this.$showError(this, err);
      });

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.graph-button {
  color: #8795A1;
  position: relative;
  outline: none;

  &--active {
    color: black;

    &:before {
      content: '';
      height: 2px;
      width: 100%;
      background-color: #3490DC;
      position: absolute;
      bottom: -6px;
      left: 0;
      outline: none;
    }
  }
}

.legend {
  width: 10px;
  height: 10px;
  background: #1C3D5A;
  display: inline-block;
  flex-shrink: 0;
  position: absolute;
  top: 3px;
  left: -15px;
}

.trend {
  font-size: 8px;

  &--green {
    color: #38C172;
  }

  &--red {
    color: #E3342F;
  }
}
</style>

<template>
  <div>
    <el-tabs
      v-model="selectedTab"
      v-loading="loading"
      class="h-full flex flex-col">
      <!-- Calls Graph -->
      <el-tab-pane
        :label="$t('column_labels.calls')"
        name="calls"
        class="flex h-full">
        <div
          v-if="callData"
          class="w-full flex">
          <div class="chart w-full lg:w-2/3 lg:mr-5 relative">
            <LineChart
              :chart-data="callData"
              :options="chartOptions"
              class="w-full vi-graph" />
            <div class="flex justify-between text-xs mt-2">
              <span>{{ callData.labels[0] }}</span>
              <span>{{ callData.labels[callData.labels.length - 1] }}</span>
            </div>
          </div>
          <div class="w-full lg:w-1/3">
            <div class="pb-5 my-5 border-b border-color-red">
              <div class="mx-5 mb-4 flex items-center">
                <svg class="vi-legend mr-2">
                  <rect
                    width="10"
                    height="10"
                    rx="2"
                    :style="{fill: '#1FA8D9'}" />
                </svg>
                <span class="text-xs leading-none">{{ $t('video.dashboard.charts[0]') }}</span>
              </div>
              <div class="flex">
                <div class="w-full mx-5">
                  <p class="text-xs text-grey-dark">
                    {{ $t('video.dashboard.charts[1]') }}
                  </p>
                  <p
                    v-if="Object.keys(callData).length"
                    class="mt-1 text-sm text-black font-semibold">
                    {{ callData.datasets[1].data.reduce((total, item) => total + item, 0) }}
                  </p>
                  <p
                    v-else
                    class="mt-1 text-sm text-grey-dark font-semibold">
                    0
                  </p>
                </div>
              </div>
            </div>

            <div class="pb-5 my-5">
              <div class="mx-5 mb-4 flex items-center">
                <svg class="vi-legend mr-2">
                  <rect
                    width="10"
                    height="10"
                    rx="2"
                    :style="{fill: '#003C5A'}" />
                </svg>
                <span class="text-xs leading-none">{{ $t('video.dashboard.charts[2]') }}</span>
              </div>
              <div class="flex">
                <div class="w-full mx-5">
                  <p class="text-xs text-grey-dark">
                    {{ $t('video.dashboard.charts[3]') }}
                  </p>
                  <p
                    v-if="Object.keys(callData).length"
                    class="mt-1 text-sm text-black font-semibold">
                    {{ callData.datasets[0].data.reduce((total, item) => total + item, 0) }}
                  </p>
                  <p
                    v-else
                    class="mt-1 text-sm text-grey-dark font-semibold">
                    0
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center p-10 text-xs text-black leading-loose w-full">
          <h1 class="text-black text-base mb-5 font-semibold">
            {{ $t('video.dashboard.charts[4]') }}
          </h1>
          <span class="text-sm text-grey block mb-5">
            {{ $t('video.dashboard.charts[5]') }}
          </span>
        </div>
      </el-tab-pane>

      <!-- Call Duration Graph -->
      <el-tab-pane
        :label="$t('column_labels.call_duration')"
        name="call-duration"
        class="flex h-full">
        <div
          v-if="durationData"
          class="w-full flex">
          <div class="chart w-full lg:w-2/3 lg:mr-5 relative">
            <LineChart
              :chart-data="durationData"
              :options="durationOptions"
              class="w-full vi-graph" />
            <div class="flex justify-between text-xs mt-2">
              <span>{{ durationData.labels[0] }}</span>
              <span>{{ durationData.labels[durationData.labels.length - 1] }}</span>
            </div>
          </div>
          <div class="w-full lg:w-1/3">
            <div class="pb-5 my-5">
              <div class="mx-5 mb-4 flex items-center">
                <svg class="vi-legend mr-2">
                  <rect
                    width="10"
                    height="10"
                    rx="2"
                    :style="{fill: '#1FA8D9'}" />
                </svg>
                <span class="text-xs leading-none">{{ $t('video.dashboard.charts[6]') }}</span>
              </div>
              <div class="flex">
                <div class="w-full mx-5">
                  <p class="text-xs text-grey-dark">
                    {{ $t('video.dashboard.charts[7]') }}
                  </p>
                  <p
                    v-if="Object.keys(callData).length"
                    class="mt-1 text-sm text-black font-semibold">
                    {{ durationData.datasets[0].data.reduce((total, item) => total + item, 0) }} seconds
                  </p>
                  <p
                    v-else
                    class="mt-1 text-sm text-grey-dark font-semibold">
                    0 {{ $tc('time_labels.second', 2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center p-10 text-xs text-black leading-loose w-full">
          <h1 class="text-black text-base mb-5 font-semibold">
            {{ $t('video.dashboard.charts[8]') }}
          </h1>
          <span class="text-sm text-grey block mb-5">
            {{ $t('video.dashboard.charts[9]') }}
          </span>
        </div>
      </el-tab-pane>

      <!-- Call Rating Graph -->
      <el-tab-pane
        :label="$t('column_labels.call_rating')"
        name="call-rating"
        class="flex h-full">
        <div
          v-if="ratingData"
          class="w-full flex">
          <div class="chart w-full lg:w-2/3 lg:mr-5 relative">
            <LineChart
              :chart-data="ratingData"
              :options="chartOptions"
              class="w-full vi-graph" />
            <div class="flex justify-between text-xs mt-2">
              <span>{{ ratingData.labels[0] }}</span>
              <span>{{ ratingData.labels[ratingData.labels.length - 1] }}</span>
            </div>
          </div>
          <div class="w-full lg:w-1/3">
            <div class="pb-5 my-5">
              <div class="mx-5 mb-4 flex items-center">
                <svg class="vi-legend mr-2">
                  <rect
                    width="10"
                    height="10"
                    rx="2"
                    :style="{fill: '#1FA8D9'}" />
                </svg>
                <span class="text-xs leading-none">{{ $t('video.dashboard.charts[10]') }}</span>
              </div>
              <div class="flex">
                <div class="w-full mx-5">
                  <p class="text-xs text-grey-dark">
                    {{ $t('video.dashboard.charts[11]') }}
                  </p>
                  <p
                    v-if="Object.keys(ratingData).length"
                    class="mt-1 text-sm text-black font-semibold">
                    {{
                      ((
                        (ratingData.datasets[0].data || [])
                          .reduce(
                            (total, item) =>
                              total + item, 0)
                        / ( ratingData.datasets[0].data || [] )
                          .filter(i => i).length
                      ))
                        .toLocaleString({ maximumFractionDigits: 2 })
                    }}
                  </p>
                  <p
                    v-else
                    class="mt-1 text-sm text-grey-dark font-semibold">
                    0
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-center p-10 text-xs text-black leading-loose w-full">
          <h1 class="text-black text-base mb-5 font-semibold">
            {{ $t('video.dashboard.charts[12]') }}
          </h1>
          <span class="text-sm text-grey block mb-5">
            {{ $t('video.dashboard.charts[5]') }}
          </span>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LineChart from '@/components/LineChart.vue';

export default {

  name: 'Charts',

  components: { LineChart },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    callData: {
      type: Object,
      default: () => {},
    },
    durationData: {
      type: Object,
      default: () => {},
    },
    durationOptions: {
      type: Object,
      default: () => {},
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    ratingData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.vi-graph {
  height: 250px;
}

.vi-legend {
  width: 10px;
  height: 10px;
}
</style>

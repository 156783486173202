import Vue from 'vue';
import Vuex from 'vuex';

import agents from './modules/agents';
import calls from './modules/calls';
import tenants from './modules/tenants';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: [],
  },

  mutations: {
    API_ERROR(state, payload) {
      // if (window.Bugsnag) {
      //   window.Bugsnag.notify(payload);
      // }
      state.errors.push(payload); // eslint-disable-line no-param-reassign
    },
  },

  actions: {},

  modules: {
    agents,
    calls,
    tenants,
    user,
  },
});

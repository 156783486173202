<template>
  <div class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('sidebar_menu_children.Agents') }}
    </h1>
    <div class="flex -mx-3">
      <div
        class="px-3 w-full">
        <div class="w-full">
          <div class="relative">
            <div
              v-if="!$route.query.app && !$route.query.status && !$route.query.q"
              class="table mb-6 bg-grey-lightest rounded-sm text-sm p-3 leading-none">
              <span>
                {{ $t('video.agents.index[0]') }}
                <strong class="text-blue-dark">
                  {{ availableAgentSlots }}
                </strong>
              </span>
              <span class="ml-2">
                {{ $t('video.agents.index[1]') }}
                <strong class="text-green">
                  {{ (unfilteredagents || []).length }}
                </strong>

              </span>
            </div>
            <Table
              v-loading="getLoading"
              :agents="agents"
              :search="searchInput"
              :can-add-agent="showAddAgent"
              :app-list="getAppList"
              :page-number="pageNumber"
              @add-agent="inviteAgent"
              @view-agent="viewDetail"
              @remove-agent="removeUser"
              @reset-password="resetPassword"
              @invite-agent="resendInvite"
              @update-agent-app="agent => updateAgentAppfunc(agent)"
              @update-page-number="page => pageNumber = page" />
          </div>
        </div>
      </div>
      <div class="main__aside px-3">
        <div class="pt-20 -mt-20 sticky pin-t">
          <!-- FILTERS -->
          <el-button
            v-if="showAddAgent"
            size="small"
            class="mb-5 w-full"
            type="primary"
            @click="inviteAgent()">
            {{ $t('video.agents.index[2]') }}
          </el-button>

          <div class="relative">
            <div class="flex p-4 pb-8 -mb-4 rounded bg-grey-lightest">
              <div class="w-full">
                <div>
                  <span class="text-xs block mb-2">{{ $t('actions.search') }}</span>
                  <el-input
                    v-model="searchInput"
                    suffix-icon="el-icon-search"
                    size="small"
                    :placeholder="$t('actions.type_to_search')"
                    @keyup.native="updateSearch" />
                </div>
                <div class="mt-4">
                  <span class="text-xs block mb-2">{{ $t('column_labels.status') }}</span>
                  <el-select
                    v-model="statusInput"
                    size="small"
                    class="w-full"
                    filterable
                    @change="updateAgentStatus">
                    <el-option
                      v-for="status in Object.keys(agentStatusOption)"
                      :key="status"
                      :label="$t(`video.agent_status_text.${agentStatusOption[status].text}`)"
                      :value="status" />
                  </el-select>
                </div>
                <div class="mt-4">
                  <span class="text-xs block mb-2">{{ $t('column_labels.queue') }}</span>
                  <el-select
                    v-model="appInput"
                    size="small"
                    class="w-full"
                    filterable
                    :placeholder="$t('video.agents.index[3]')"
                    :disabled="!getAppList.length"
                    @change="updateAgentAppFilter">
                    <el-option
                      v-for="app in [{ name: $t('column_labels.all'), value: '' }, ...getAppList]"
                      :key="app.value"
                      :label="app.name"
                      :value="app.value" />
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import _ from 'lodash'; // eslint-disable-line

import timeFormatter from '@/mixins/timeFormatter';
import agentMixin from '@/mixins/agent';
import highlight from '@/mixins/highlight';

import agentStatus from '@/json/agentStatus';

import Table from './partials/Table.vue';


export default {

  components: {
    Table,
  },

  mixins: [timeFormatter, agentMixin, highlight],

  props: {
    q: {
      type: String,
      required: false,
      default: '',
    },
    status: {
      type: String,
      required: false,
      default: '',
    },
    app: {
      type: String,
      required: false,
      default: '',
    },
    selected: {
      type: String,
      required: false,
      default: '',
    },
    modal: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    const agentStatusOption = {
      '': {
        text: 'All',
        description: '',
      },
      ...agentStatus,
    };
    return {
      subscriptionEndDate: null,
      searchInput: '',
      statusInput: '',
      appInput: '',
      userData: {},
      agentStatus,
      agentStatusOption,
      pageNumber: 1,
    };
  },

  computed: {
    ...mapState({
      tenant: state => state.tenants.tenant,
      unfilteredagents: state => state.agents.agents,
      totalAgentsCount: state => state.agents.totalAgentsCount,
    }),

    ...mapGetters({
      getCurrAgent: 'getCurrentAgent',
      agents: 'getAgents',
      getTotal: 'getTotal',
      getAvailableTotal: 'getAvailableTotal',
      getLoading: 'getLoading',
      getSubscriptionEnd: 'getSubscriptionEnd',
      getAppList: 'getAppList',
    }),

    availableAgentSlots() {
      const slots = this.maxUser - this.totalAgentsCount;

      if (slots < 0) {
        return 0;
      }

      return slots;
    },

    agents() {
      let agent = this.unfilteredagents;
      if (this.searchInput.length) {
        agent = this.unfilteredagents.filter(agt => agt.Login.trim().toLowerCase().includes(this.searchInput.trim().toLowerCase()) || agt.Name.trim().toLowerCase().includes(this.searchInput.trim().toLowerCase()));
      }
      if (this.appInput) {
        agent = agent.filter(a => a.AppId_Default === this.appInput);
      }
      return agent;
    },

    maxUser() {
      let max = 0;
      if (this.tenant && this.tenant.MaxAgents) {
        max = this.tenant.MaxAgents;
      }
      return max;
    },

    showAddAgent() {
      let show = false;
      if (this.tenant.MaxAgents && this.availableAgentSlots > 0) {
        show = true;
      }
      return show;
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    '$route.query': function (newQuery) {
      this.$store.dispatch('fetchAgents', newQuery)
        .catch((err) => {
          this.$showError(this, err);
        });
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions({
      fetchTenant: 'fetchTenant',
      fetchAppList: 'fetchAppList',
      updateAgentApp: 'updateAgentApp',
    }),

    // Update query
    updateFilterQuery(query) {
      if (!this.disabled) {
        this.$router.push({
          query: {
            ...this.$route.query,
            ...query,
          },
        });
      }
    },

    async init() {
      try {
        await this.fetchData();
        await this.fetchAppList();

        try {
          const user = localStorage.getItem('CPV3_User') || {};

          this.userData = JSON.parse(user);
        } catch (e) {
          // do nothing
        }

        if (this.userData.AccountId) {
          await this.fetchTenant({
            uId: this.userData.AccountUid,
            accountId: this.userData.AccountId,
          });
        }
      } catch (err) {
        this.$showError(this, err);
      }
    },

    async updateAgentAppfunc(agent) {
      try {
        await this.updateAgentApp(agent);
      } catch (err) {
        this.$showError(this, err);
      }
    },

    // Open/Close agent profile modal
    viewDetail(row) {
      this.updateFilterQuery({ modal: 'AgentProfile', selected: row.UserId });
    },

    // Reset the password of the user
    resetPassword(id) {
      this.$store.dispatch('resetPassword', id)
        .then((agent) => {
          this.$message.success({ message: `${this.$t('video.agents.index[4]')} ${agent}` });
        })
        .catch((err) => {
          this.$showError(this, err);
        });
    },

    // Remove a user
    removeUser(id) {
      const self = this;
      const agent = this.agents.filter(agt => agt.UserId === id)[0];
      const agentName = agent.Name || agent.Login;
      this.$confirm(
        `${self.$t('video.agents.index[5]')} ${agentName}?`,
        self.$t('app_labels.warning'),
        {
          type: 'warning',
          center: true,
          cancelButtonText: self.$t('actions.cancel'),
          confirmButtonText: self.$t('actions.yes'),
        },
      )
        .then(() => {
          self.$store.dispatch('removeAgent', id)
            .then((at) => {
              // hide modal
              self.$message.success(self.$t('success.removed', { value: at }));
              const { selected, modal, ...query } = self.$route.query;
              self.$router.push({ query });
            }).catch((err) => {
              self.$showError(self, err);
            });
        });
    },

    // Resend invite to user
    resendInvite(id) {
      const self = this;
      const agent = this.agents.filter(agt => agt.UserId === id)[0];
      const agentName = agent.Name || agent.Login;
      this.$confirm(
        `${self.$t('video.agents.index[7]')} ${agentName}?`,
        self.$t('app_labels.warning'),
        {
          type: 'warning',
          center: true,
          cancelButtonText: self.$t('actions.cancel'),
          confirmButtonText: self.$t('actions.yes'),
        },
      )
        .then(() => {
          self.$store.dispatch('resendInvite', id)
            .then(() => {
              // hide modal
              self.$message.success(`${agentName} ${self.$t('video.agents.index[6]')}`);
            }).catch((err) => {
              self.$showError(self, err);
            });
        });
    },

    // Fetch Data
    async fetchData() {
      const { q, status, app } = this;

      this.searchInput = q || '';
      this.statusInput = status || '';
      this.appInput = app ? parseInt(app, 10) : '';

      try {
        // Fetch required datas on load page
        this.$store.dispatch('fetchAgents', { q, status, app });
        this.$store.dispatch('fetchSubscriptionEnd');
        this.$store.dispatch('fetchTotalAgentsCount');
      } catch (err) {
        this.$showError(this, err);
      }
    },

    // Update Agent Search
    updateSearch: _.debounce(
      function update() {
        this.updateFilterQuery({
          q: this.searchInput.trim(),
        });
      }, 300,
    ),

    // Update Agent Status filter
    updateAgentStatus(val) {
      this.updateFilterQuery({ status: val });
    },

    // Update Agent app filter
    updateAgentAppFilter(val) {
      this.updateFilterQuery({ app: val });
    },
  },
};
</script>

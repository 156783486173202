import axios from 'axios';
import qs from 'query-string';

// Set base API URL
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Intercept reponse
axios.interceptors.response.use((response) => {
  if (response.headers && response.headers.authorization) {
    localStorage.setItem('WWW-Authenticate', response.headers.authorization);
  }

  return response;
}, err => Promise.reject(err.response.data));

// Intercept request to automatically add Authorization header
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_KEY || 'WWW-Authenticate');

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // eslint-disable-line
  }

  return config;
}, err => Promise.reject(err));

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
const request = options => axios(options);

const get = (url, params = {}) => axios.get(url, {
  params: {
    ...params,
  },
  paramsSerializer(p) {
    return qs.stringify(p, { indices: false });
  },
});

const post = (url, data = {}, config = {}) => axios.post(url, data, config);

const put = (url, data = {}) => axios({ method: 'put', url, data });

const del = (url, data = {}) => axios({ method: 'delete', url, data });

export default {
  request,
  get,
  post,
  put,
  del,
};

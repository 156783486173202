<template>
  <div class="flex">
    <!-- Week Select -->
    <span class="text-white pt-2">
      {{ tz.timeZone }}&nbsp;
    </span>
    <el-select
      v-model="week"
      :disabled="loading"
      :placeholder="$t('video.performance.filters[0]')"
      size="small"
      class="ml-3"
      @change="val => $emit('update-filter', { weeks:val })">
      <el-option
        v-for="item in weekOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value" />
    </el-select>

    <!-- App Select -->
    <el-select
      v-model="app"
      :disabled="loading"
      :placeholder="$t('video.incoming_call.filters[0]')"
      size="small"
      class="ml-3"
      @change="val => $emit('update-app', val)">
      <el-option
        v-for="item in appOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value" />
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Filters',

  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    week: {
      required: true,
      type: String,
    },
    weekOptions: {
      required: true,
      type: Array,
    },
    app: {
      required: true,
      type: String,
    },
    appOptions: {
      required: true,
      type: Array,
    },
  },

  computed: {
    ...mapGetters({
      tz: 'getTz',
    }),
  },
};
</script>

import moment from 'moment';
import momentDuration from 'moment-duration-format';

momentDuration(moment);

export default {
  methods: {
    // Time difference between current time and given time
    timeFromNow(datetime) {
      return moment(datetime).fromNow();
    },

    // Format date
    formatDate(datetime, option) {
      const defaultOption = {
        format: 'D MMM YYYY',
        showCurrYear: true,
        utcOffset: 0,
      };
      option = { ...defaultOption, ...option };
      option.format = !option.showCurrYear && moment(datetime).format('YYYY') === moment().format('YYYY')
        ? option.format.replace(/[yY]/g, '').trim()
        : option.format;
      return moment.utc(datetime).utcOffset(option.utcOffset, false).format(option.format);
    },

    // Relative to Calendar
    timeFromCalendar(datetime) {
      return moment(datetime).calendar(null, {
        sameDay: '[Today], hh:mm A',
        lastDay: '[Yesterday], hh:mm A',
        nextDay: '[Tomorrow], hh:mm A',
        lastWeek: '[Last] ddd, hh:mm A',
        nextWeek: 'ddd, hh:mm A',
        sameElse() {
          return this.year() === moment().year() ? 'D MMM, hh:mm A' : 'D MMM YYYY, hh:mm A';
        },
      });
    },

    // Format duration: param seconds
    FormatDuration(s) {
      const format = s >= 3600 ? 'hh:mm:ss' : 'mm:ss';
      return moment.duration(s, 'second').format(format, { trim: false });
    },

    // Format labelled duration : param seconds and custom label name
    FormatLabelledDuration(s, unit = {}) {
      unit = {
        h: 'hrs', m: 'min', s: 'sec', ...unit,
      };
      return moment.duration(s, 'second').format(`h [${unit.h}], m [${unit.m}], s [${unit.s}]`);
    },

    // Format labelled duration : param seconds and custom label name
    decimalToDuration(dec) {
      return moment.utc(moment.duration(dec, 'minutes').asMilliseconds()).format('HH:mm:ss');
    },

    // Month Day Year, Hour Min Sec
    formatFullDateTime(datetime) {
      return moment(datetime).format('MMM Do YYYY, h:mm:ss a');
    },

    getDateByFormat(datetime, format = 'YYYY-MM-DD HH:mm:ss') {
      return moment(datetime).format(format);
    },

    getLocalDate(date, offset, tz = '') {
      const d = moment(date).utcOffset(offset, false).format('DD MMM YYYY');

      return String(`${d} ${tz}`).trim();
    },

    getLocalDateTime(date, offset) {
      return moment.utc(date).utcOffset(offset, false).format('D MMM YYYY hh:mm A');
    },

    getLocalDateTimeTz(date, offset, tz = '', format = 'D MMM YYYY hh:mm A') {
      const d = moment.utc(date).utcOffset(offset, false).format(format);

      return String(`${d} ${tz}`).trim();
    },

    getUtcDate(date, format) {
      return moment.utc(date).format(format);
    },

    getDateHeader(tz, text) {
      return `${text || this.$t('column_labels.date')} ${tz}`;
    },
  },
};

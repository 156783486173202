<template>
  <div
    id="video"
    class="h-full">
    <RouterView />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },

  async created() {
    await this.getUser();
  },

  beforeDestroy() {
    this.$el.className = '';
  },

  mounted() {
    // window.addEventListener('localeChanged', () => {
    //   this.$i18n.locale = this.$getSelectedLocale();
    // });
  },

  methods: {
    ...mapActions({
      getUser: 'getUser',
    }),
  },
};
</script>

<style lang="scss">
  @font-face {
    font-family: 'icomoon';
    src:  url('./assets/icons/icomoon.eot?6apa1c');
    src:  url('./assets/icons/icomoon.eot?6apa1c#iefix') format('embedded-opentype'),
      url('./assets/icons/icomoon.ttf?6apa1c') format('truetype'),
      url('./assets/icons/icomoon.woff?6apa1c') format('woff'),
      url('./assets/icons/icomoon.svg?6apa1c#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-v-tiny-arrow-down:before {
    content: "\ee5c5";
  }
  .icon-v-tiny-arrow-up:before {
    content: "\ee5c7";
  }
  .icon-v-timer:before {
    content: "\ee01b";
  }
  .icon-v-call_made:before {
    content: "\ee0b2";
  }
  .icon-v-call_received:before {
    content: "\ee0b5";
  }
  .icon-v-smile:before {
    content: "\ee420";
  }
  .icon-v-external-link:before {
    content: "\ee89e";
  }
  .icon-v-stopwatch:before {
    content: "\ee425";
  }
  .icon-v-videocam:before {
    content: "\ee04b";
  }
  .icon-v-icm:before {
    content: "\ee908";
  }
  .icon-v-trend-up:before {
    content: "\ee906";
  }
  .icon-v-trend-down:before {
    content: "\ee907";
  }
  .icon-v-agent:before {
    content: "\ee900";
  }
  .icon-v-call-log:before {
    content: "\ee901";
  }
  .icon-v-dashboard:before {
    content: "\ee902";
  }
  .icon-v-overview:before {
    content: "\ee903";
  }
  .icon-v-performance:before {
    content: "\ee904";
  }
  .icon-v-plus:before {
    content: "\ee905";
  }
</style>
